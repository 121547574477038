import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit, OnDestroy, AfterViewInit, Output, ViewChild, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DateTimeFormatOptions } from 'luxon';
import { Observable, Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { Address } from 'projects/common/src/lib/address';
import { Category, CatItem } from 'projects/common/src/lib/category';
import { Item } from 'projects/common/src/lib/item';
import { Merchant } from 'projects/common/src/lib/merchant';
import { Transaction } from 'projects/common/src/lib/transaction';
import { Option } from 'projects/common/src/lib/option';
import { Regex } from 'projects/common/src/lib/regex';
import { ISchedule, ScheduleType, StoreCartModel, TipType } from 'projects/common/src/lib/store-cart.model';
import { User } from 'projects/common/src/lib/user';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { LoggerService } from 'projects/services/src/lib/logger.service';
import { LogEntry } from 'projects/common/src/lib/log-entry';
import { ItemService } from 'projects/services/src/lib/item.service';
import { MerchantService } from 'projects/services/src/lib/merchant.service';
import { StoreCartService } from 'projects/services/src/lib/store-cart.service';
import { EmailService } from 'projects/services/src/lib/email.service';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import { Info } from 'projects/common/src/lib/info';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { DialogComponent } from '../dialog/dialog.component';
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';
import { CardInfo } from 'projects/common/src/lib/cardinfo';
import { ScheduleDialogComponent, IScheduleDialogData } from '../schedule-dialog/schedule-dialog.component';
import { Order, OrderConfirmation, DeviceDetails, OrderStatus } from 'projects/common/src/lib/order';
import { Payment } from 'projects/common/src/lib/payment';
import { OrderService } from 'projects/services/src/lib/order.service';
import { SmsService } from 'projects/services/src/lib/sms.service';
import { SideNavService } from 'projects/services/src/lib/side-nav.service';
import { ConfigService } from 'projects/services/src/lib/config.service';
import { OrderType, OrderTypeEnum } from 'projects/common/src/lib/ordertype';
import { NgxSpinnerService } from 'ngx-spinner';
/* import { GooglePay } from 'projects/common/src/lib/googlepay/google-pay';
import { SamsungPay } from 'projects/common/src/lib/samsungpay/samsung-pay'; */
import { ApplePay } from 'projects/common/src/lib/applepay/apple-pay-js';
import { MatStepper } from '@angular/material/stepper';
import { OperationHours } from 'projects/common/src/lib/operation-hours';

import { LogIn } from 'projects/common/src/lib/login';
import { EtcPaymentResponse } from 'projects/common/src/lib/etc-transaction-response';
import { EmailOrderTemplate } from 'projects/common/src/lib/email-order-template';
import { EmailOrderConfirmation } from 'projects/common/src/lib/email-order-confirmation';
// import moment from 'moment';
const moment = require('moment-timezone');
import { Overlay } from '@angular/cdk/overlay';
import { DeliveryService } from 'projects/services/src/lib/delivery.service';
import { TipSettings } from 'projects/common/src/lib/merchant-settings';
import { v4 as uuid } from 'uuid';

import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from 'projects/services/src/lib/common.service';
import { DomSanitizer } from '@angular/platform-browser';

interface Window {
  innerWidth: number;
  location: any;
  ApplePaySession: any;
  PaymentRequest: any;    // samsung
}

declare var window: Window;

interface ItemCacheRecord {
  categoryid: string;
  category: string;
  items: Item[];
}

@Component({
  selector: 'app-store-checkout',
  templateUrl: './store-checkout.component.html',
  styleUrls: ['./store-checkout.component.scss']
})
export class StoreCheckoutComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('stepper') stepper: MatStepper;
  public order: Order = new Order();
  private itemCache: ItemCacheRecord[] = [];
  private cartSubscription: Subscription;
  private userSubscription: Subscription;
  private emailSubscription: Subscription;
  private merchantEmailSubscription: Subscription;
  private smsSubscription: Subscription;
  private merchantSmsSubscription: Subscription;
  private configSubscription: Subscription;
  private deliverySubscription: Subscription;
  private loggerSubscription: Subscription;
  private merchantSubscription: Subscription;
  private itemSubscription: Subscription;
  private orderSubscription: Subscription;
  private dateFormatOptions = { dateStyle: 'medium' } as DateTimeFormatOptions;
  private timeFormatOptions = { hour: 'numeric', hour12: true, minute: '2-digit' } as DateTimeFormatOptions;

  public orderType: string;
  public addressTitle = 'To:';
  public customTip: string;

  public sidenavOptions = {
    sidenavMode: 'side',
    disableClose: true,
    backdrop: false
  };
  public user: User;
  public merchantInfo: Merchant;
  public categories: Category[];
  public selectedCategory: string;
  public selectedSubCategory: Category;
  public categoryItems: ItemCacheRecord[];
  public searchText: string;
  public merchantAddress: string;
  public CategoryButtonClicked = true;
  public cartModel: StoreCartModel = new StoreCartModel();
  public authCheck: boolean;
  public resizeSidenav = true;
  public showToolbar = false;
  public windowWidth: number;
  public item: Item;
  public itemIdx: number;
  public updateFlag = false;
  public scheduleText: string;
  public modalItem: Item = new Item();
  public timer: any;

  // Payment
  public innerWidth: number;
  /* public googlePay: GooglePay = new GooglePay();
  public samsungPay: SamsungPay = new SamsungPay(); */
  public applePay: ApplePay;
  public deliveryFee = 0;
  public inRange = true;

  // new for checkout:
  public showDI = false;
  public textDI: string;
  public regexList: Regex = new Regex();
  public deliveryInfo: Info;

  public defaultOperationHours = [];
  public waittime = 0;

  public stepperIndex = 0;
  public orderResponse: any;

  // need to set this to the first valid payment option for the user if they are logged in:
  public paymentMethod: CardInfo;
  public processing = {
    inprogress: false,
    done: false,
    error: false
  };

  public orderTypes = [];
  public defaultOrderType = {
    text: '',
    value: null
  };

  public messages = ['a', 'b', 'c'];
  public isCustomerInfoValid = false;
  public isPaymentInfoValid = false;

  public tip = new TipSettings();
  public selectedTip = -1;

  dialogConfig = new MatDialogConfig();

  sidenavObservable: Observable<boolean>;
  sidenavSubscription: Subscription;
  @Output() sidenavState = false;

  orderConfig: any;
  baseUrl = 'https://www.deliverme.com';

  applePayConfig: any = {};

  disableApplePay = false;
  public isClosed = false;

  constructor(
    public http: HttpClient,
    private activeRoute: ActivatedRoute,
    private _router: Router,
    private _merchantSvc: MerchantService,
    private _itemSvc: ItemService,
    private _cartSvc: StoreCartService,
    private authSvc: AuthenticationService,
    private _smsSvc: SmsService,
    private delService: DeliveryService,
    public orderService: OrderService,
    public sideNavService: SideNavService,
    public emailService: EmailService,
    private overlay: Overlay,
    private _loginDialog: MatDialog,
    private _scheduleDialog: MatDialog,
    private confirmDialog: MatDialog,
    private _addressDialog: MatDialog,
    private _paymentDialog: MatDialog,
    private localStorage: StorageMap,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public location: Location,
    private configService: ConfigService,
    private loggerService: LoggerService,
    private deviceService: DeviceDetectorService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer
  ) {

    this.merchantSubscription = this._merchantSvc.merchantEmitter.subscribe(async (merchant: Merchant) => {
      await this.selectedMerchant(merchant);
    });
    if (this.cartModel) {
      this.cartModel.deliverytypetext = '';
    }

    this.sidenavSubscription = this.sideNavService.state.subscribe((state: boolean) => {
      // if (this.screenWidth < 500) {
      this.sidenavState = state;
      // }
    });

    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }

    const storename = this.activeRoute.snapshot.params.storename;

    /*     if (this.cartSubscription) {
          this.cartSubscription.unsubscribe();
        } */

    this.cartSubscription = this._cartSvc.StoreCartModelEmitter.subscribe(async (model: StoreCartModel) => {
      this.cartModel = model;

      if (this.cartModel.deliveryInstructions) {
        this.showDI = true;
      }

      this.localStorage.get('merchant').subscribe(async (merchant: Merchant) => {
        this.merchantInfo = merchant;

        if (this.merchantInfo) {
          this.localStorage.get(this.merchantInfo._id).subscribe((model: StoreCartModel) => {
            this.cartModel = model;

            if (this.cartModel.deliverytype === OrderTypeEnum.Delivery) {
              this.defaultOperationHours = this.merchantInfo.settings.deliveryHours;
              this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
            } else {
              this.defaultOperationHours = this.merchantInfo.settings.pickupHours;
              this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
            }

            if (!this.cartModel.deliverytype || this.cartModel.deliverytype <= 0) {
              this.cartModel.deliverytype = this.defaultOrderType.value;
              this._cartSvc.UpdateDeliveryType(this.defaultOrderType.value);
            }

            if (this.cartModel.deliveryInstructions) {
              this.showDI = true;
            }

            this._formatSchedule();
          });
        }

      });
    });

  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    const storename = this.activeRoute.snapshot.params.storename;
    this.baseUrl = window.location.origin;

    this.localStorage.get('user').subscribe(async (user: User) => {
      this.user = user;
      if (!this.user || (this.user && (!this.user.access_token || this.user.isGuestLogin))) {
        const guestuser = await this.GuestLogin();
        this.user.access_token = guestuser.access_token;
        this.localStorage.set('user', guestuser);
        localStorage.setItem('token', guestuser.access_token); // this is not the same as 'this.localStorage'
        this.localStorage.set('token', guestuser.access_token).subscribe(() => { });
      } else {
        this.authSvc.validateToken(this.authSvc.AuthToken).subscribe((result: any) => {
          // token is valid
          this.setCustomerInfo();
        }, async (error: any) => {
          // token isn't valid
          localStorage.delete('token');
          this.localStorage.delete('token').subscribe(() => { });
          this.localStorage.delete('user').subscribe(() => { });
          this.user.access_token = '';
          this.authSvc.SetAuthState(this.user);
          const guestuser = await this.GuestLogin();
        });
      }

      this.configSubscription = this.configService.getConfig('api/orders').subscribe(async (config: any) => {
        this.orderConfig = config;
        if (storename) {

          this.localStorage.get('merchant').subscribe(async (merchant: Merchant) => {

            this.merchantInfo = merchant; // await this._LoadMerchant(storename);
            await this.selectedMerchant(merchant);
            // this.tip = this.merchantInfo.settings.tipSettings;
            // this.tip.tipAmounts = this.tip.tipAmounts.filter(t => t.enable && t.amount !== 'Custom');
            // if (this.tip.defaultIndex === 5) {
            //   this.selectedTip = 5;
            //   this.customTip = '0.00';
            // } else {
            //   this.selectedTip = this.tip.tipAmounts.findIndex(a => a.amount === this.merchantInfo.settings.tipSettings.tipAmounts[this.merchantInfo.settings.tipSettings.defaultIndex].amount);
            // }

            this.cartSubscription = this._cartSvc.StoreCartModelEmitter.subscribe(async (model: StoreCartModel) => {
              this.cartModel = model;

              if (this.cartModel.deliveryInstructions) {
                this.showDI = true;
              }

              this._formatSchedule();

              if (this.cartModel.deliverytype < 0) {
                this.cartModel.deliverytype = this.defaultOrderType.value;
              } else {
                if (this.cartModel.deliverytype === OrderTypeEnum.Delivery) {
                  this.defaultOperationHours = this.merchantInfo.settings.deliveryHours;
                  this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 0;
                } else {
                  this.defaultOperationHours = this.merchantInfo.settings.pickupHours;
                  this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 0;
                }
              }

              if (!this.cartModel.deliverytype || this.cartModel.deliverytype <= 0) {
                this.cartModel.deliverytype = this.defaultOrderType.value;
                this._cartSvc.UpdateDeliveryType(this.defaultOrderType.value);
              }

            });

            await this.reloadOrder();

            if (this.cartSubscription) {
              this.cartSubscription.unsubscribe();
            }

            this._SetupUser();  // This has to come after loading the delivery address info else will get invalid address error.

            if (this.configSubscription) {
              this.configSubscription.unsubscribe();
            }

            this.configSubscription = this.configService.getConfig('applepay').subscribe((appleConfig: any) => {
              this.applePayConfig = appleConfig;

              if (window.ApplePaySession) {
                this.order.useWalletShippingAddress = true;
                this.applePay = new ApplePay(this.order, this.http, this.orderService, this.applePayConfig);
                this.applePay.onApplePayLoaded();
              }

            }, (error: any) => {
              console.log(error.message);
            });
          });
        } else {
          this._router.navigate(['/pagenotfound']);
        }

      }, (error: any) => {
        console.log(error.message);
      });
    });

    // this._SetupUser();

    if (this.orderConfig && this.orderConfig.url) {
      this.baseUrl = this.orderConfig.url ? this.orderConfig.url : this.baseUrl;
    }

    if (window.ApplePaySession) {
      this.applePay = new ApplePay(this.order, this.http, this.orderService, this.applePayConfig);
      this.applePay.onApplePayLoaded();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    this.userSubscription = this.authSvc.CurrentUserEmitter.subscribe((u: User) => {
      if (u) {
        this.user = u;

        if (!u.isGuestLogin) {
          const address = this.user.addresses.find(a => a.isDefault === true);

          this.deliveryInfo = new Info();
          this.deliveryInfo.addresses = this.user.addresses;
          this.deliveryInfo.name = (this.user.firstname && this.user.lastname) ? this.user.firstname + ' ' + this.user.lastname : '';
          this.deliveryInfo.firstname = this.user.firstname;
          this.deliveryInfo.lastname = this.user.lastname;
          this.deliveryInfo.phone = this.user.phone;
          this.deliveryInfo.email = this.user.email;
          this.deliveryInfo.address.street1 = (address) ? address.street1 : this.user.street1;
          this.deliveryInfo.address.street2 = (address) ? address.street1 : this.user.street2;
          this.deliveryInfo.address.city = (address) ? address.city : this.user.city;
          this.deliveryInfo.address.state = (address) ? address.state : this.user.state;
          this.deliveryInfo.address.postalCode = (address) ? address.postalCode : this.user.postalCode;
          this.deliveryInfo.address.country = (address) ? address.country : this.user.country || 'USA';
          this.deliveryInfo.addressF = this.deliveryInfo.address.street1 + this.deliveryInfo.address.street2 + ', ' + this.deliveryInfo.address.city + ', ' + this.deliveryInfo.address.state + ' - ' + this.deliveryInfo.address.postalCode;

          this.localStorage.set('deliveryinfo', this.deliveryInfo).subscribe((di: any) => { });

          this.order.customerInformation.name = (this.deliveryInfo.name) ? this.deliveryInfo.name.trim() : '';
          this.order.customerInformation.firstName = this.deliveryInfo.firstname;
          this.order.customerInformation.lastName = this.deliveryInfo.lastname;
          this.order.customerInformation.email = this.deliveryInfo.email;
          this.order.customerInformation.phone = this.deliveryInfo.phone;
          this.order.customerInformation.street1 = this.deliveryInfo.address.street1;
          this.order.customerInformation.street2 = this.deliveryInfo.address.street2 || '';
          this.order.customerInformation.city = this.deliveryInfo.address.city;
          this.order.customerInformation.state = this.deliveryInfo.address.state;
          this.order.customerInformation.postalcode = this.deliveryInfo.address.postalCode;
          this.order.customerInformation.country = this.deliveryInfo.address.country || 'USA';
        }

        if (this.deliveryInfo && this.deliveryInfo.name && this.deliveryInfo.phone && this.deliveryInfo.email) {
          this.isCustomerInfoValid = true;
        } else {
          this.isCustomerInfoValid = false;
        }
      }

      if (this.deliveryInfo && this.deliveryInfo.name && this.deliveryInfo.email && this.deliveryInfo.phone) {
        this.isCustomerInfoValid = true;
      }
    });

  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    // this._formatSchedule();
  }

  ngOnDestroy() {

    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.deliverySubscription) {
      this.deliverySubscription.unsubscribe();
    }

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }

    clearInterval(this.timer);

    if (this.sidenavSubscription) {
      this.sidenavSubscription.unsubscribe();
    }

    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }

    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }

    if (this.merchantEmailSubscription) {
      this.merchantEmailSubscription.unsubscribe();
    }

    if (this.smsSubscription) {
      this.smsSubscription.unsubscribe();
    }

    if (this.merchantSmsSubscription) {
      this.merchantSmsSubscription.unsubscribe();
    }

    if (this.merchantSubscription) {
      this.merchantSubscription.unsubscribe();
    }
    // this.userSub.unsubscribe();
    // window.removeEventListener('resize', this.WindowResizeCheck);
  }

  public setCustomerInfo(): void {
    try {
      if (!this.user.isGuestLogin) {
        const address = this.user.addresses.find(a => a.isDefault === true);

        if (!this.deliveryInfo.address.street1 || !this.deliveryInfo.address.city) {
          this.deliveryInfo = new Info();
          this.deliveryInfo.addresses = this.user.addresses;
          this.deliveryInfo.name = (this.user.firstname && this.user.lastname) ? this.user.firstname + ' ' + this.user.lastname : '';
          this.deliveryInfo.firstname = this.user.firstname;
          this.deliveryInfo.lastname = this.user.lastname;
          this.deliveryInfo.phone = this.user.phone;
          this.deliveryInfo.email = this.user.email;
          this.deliveryInfo.address.street1 = (address) ? address.street1 : this.user.street1;
          this.deliveryInfo.address.street2 = (address) ? address.street1 : this.user.street2;
          this.deliveryInfo.address.city = (address) ? address.city : this.user.city;
          this.deliveryInfo.address.state = (address) ? address.state : this.user.state;
          this.deliveryInfo.address.postalCode = (address) ? address.postalCode : this.user.postalCode;
          this.deliveryInfo.address.country = (address) ? address.country : this.user.country || 'USA';
          this.deliveryInfo.addressF = this.deliveryInfo.address.street1 + this.deliveryInfo.address.street2 + ', ' + this.deliveryInfo.address.city + ', ' + this.deliveryInfo.address.state + ' - ' + this.deliveryInfo.address.postalCode;

          this.localStorage.set('deliveryinfo', this.deliveryInfo).subscribe((di: any) => { });

          this.order.customerInformation.name = (this.deliveryInfo.name) ? this.deliveryInfo.name.trim() : '';
          this.order.customerInformation.firstName = this.deliveryInfo.firstname;
          this.order.customerInformation.lastName = this.deliveryInfo.lastname;
          this.order.customerInformation.email = this.deliveryInfo.email;
          this.order.customerInformation.phone = this.deliveryInfo.phone;
          this.order.customerInformation.street1 = this.deliveryInfo.address.street1;
          this.order.customerInformation.street2 = this.deliveryInfo.address.street2 || '';
          this.order.customerInformation.city = this.deliveryInfo.address.city;
          this.order.customerInformation.state = this.deliveryInfo.address.state;
          this.order.customerInformation.postalcode = this.deliveryInfo.address.postalCode;
          this.order.customerInformation.country = this.deliveryInfo.address.country || 'USA';
        }

        if (this.deliveryInfo && this.deliveryInfo.name && this.deliveryInfo.phone && this.deliveryInfo.email) {
          this.isCustomerInfoValid = true;
        } else {
          this.isCustomerInfoValid = false;
        }
      }
    } catch (error) {

    }
  }
  public setActive(): boolean {
    return this.sidenavState;
  }

  private async reloadOrder(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {

        this.localStorage.get('schedule').subscribe((r: any) => {
          if (r) {
            this.cartModel.schedule = r;
            this._formatSchedule();
          }

          this.localStorage.get('deliveryinfo').subscribe((di: any) => {
            if (di) {
              this.deliveryInfo = di;
              this.deliveryInfo.orderType = this.order.ordertype

              if (di.addresses.filter(x => x.isDefault).length > 0) {
                this.deliveryInfo.address = JSON.parse(JSON.stringify(di.addresses.filter(x => x.isDefault)[0] || new Address())) as Address;
              }

              // if (!this.cartModel.deliverytype) {
              //   this.cartModel.deliverytype = this.defaultOrderType.value;
              //   this._cartSvc.UpdateDeliveryType(this.defaultOrderType.value);
              //   // this.SetDeliveryType(this.defaultOrderType.value);
              // }

              this.order.customerInformation.name = this.deliveryInfo.name;
              this.order.customerInformation.firstName = this.deliveryInfo.firstname;
              this.order.customerInformation.lastName = this.deliveryInfo.lastname;
              this.order.customerInformation.email = this.deliveryInfo.email;
              this.order.customerInformation.phone = this.deliveryInfo.phone;
              this.order.customerInformation.street1 = this.deliveryInfo.address.street1;
              this.order.customerInformation.street2 = this.deliveryInfo.address.street2 || '';
              this.order.customerInformation.city = this.deliveryInfo.address.city;
              this.order.customerInformation.state = this.deliveryInfo.address.state;
              this.order.customerInformation.postalcode = this.deliveryInfo.address.postalCode;
              this.order.customerInformation.country = this.deliveryInfo.address.country || 'USA';

              if (this.deliveryInfo && this.deliveryInfo.orderType === OrderTypeEnum.Delivery) {
                this._cartSvc.setDeliveryPosition(this.deliveryInfo.addressF).then((result: any) => {
                  if (result) {
                    this.inRange = result.inRange;
                    this.order.customerInformation.deliveryDistance = parseFloat(result.distance.toFixed(2));
                    this.order.customerInformation.lat = result.lat;
                    this.order.customerInformation.lng = result.lng;

                    this.deliverySubscription = this.delService.getGeocode(this.deliveryInfo.addressF).subscribe(d => {
                      if (d) {
                        this.order.customerInformation.geolocation = {
                          lat: d.lat,
                          lng: d.lng,
                        };
                      }
                    });

                    if (!result.inRange) {
                      this.confirmDialog.open(ConfirmDialogComponent, {
                        data: { message: 'Order Out of Range', text: 'Your order is out of our delivery range; please enter another delivery address or select another order option! We apologize for the inconvenience!' },
                        width: 'calc(40vw)'
                      });
                    }
                  }
                  // if (!result) {
                  //   this.confirmDialog.open(ConfirmDialogComponent, {
                  //     data: { message: 'Order Out of Range', text: 'Your order is out of our delivery range; please enter another delivery address or select another order option! We apologize for the inconvenience!' }
                  //   });
                  // }
                });
              }
              if (this.deliveryInfo.name) {
                this.isCustomerInfoValid = true;
              }

              this.localStorage.set('order', this.order).subscribe(() => { });
            }

            // this.setDeliveryPosition();

            this.localStorage.get('order').subscribe((order: Order) => {
              if (order) {
                this.order = order;
                this.order.ordertype = this._cartSvc.DeliveryType;
                this.cartModel.deliverytype = this._cartSvc.DeliveryType;
                this.cartModel.deliveryInstructions = this.order.deliveryInstructions;

                if (!this.cartModel.itemList) {
                  this.cartModel.itemList = this.order.items;
                  this.cartModel.tax.Value = this.order.tax;
                  this.cartModel.subtotal.Value = this.order.subtotal;
                  this.cartModel.total.Value = this.order.totalAmount;
                }

              } else {
                this.order = new Order();
                this.cartModel.deliverytype = this._cartSvc.DeliveryType;
                this.order.ordertype = this._cartSvc.DeliveryType;
                this.localStorage.set('order', this.order).subscribe(() => { });
              }

              // if (this.cartModel.tip.Type === TipType.Flat && this.cartModel.tip.Flat) {
              //   this.customTip = '$' + this.cartModel.tip.Flat.toString();
              // } else {
              //   this.customTip = '';
              // }

              this.customTip = '';

              this.localStorage.get('tip').subscribe((tip: any) => {
                if (tip) {
                  this.cartModel.tip = tip;
                }

                this.localStorage.get('paymethod').subscribe((p: any) => {
                  if (p) {

                    const data = new CardInfo();
                    for (const prop in p) {
                      if (prop) {
                        data[prop] = p[prop];
                      }
                    }

                    this.paymentMethod = data;

                    if (this.paymentMethod.cardnumber) {
                      this.isPaymentInfoValid = true;
                    }

                  }

                  resolve();
                });

              });

            });
          });
        });

      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  deliverInstructionsChanged(): void {
    try {
      this.order.deliveryInstructions = this.cartModel.deliveryInstructions;

      this.localStorage.set(this.merchantInfo._id, this.cartModel).subscribe(() => {
        this.localStorage.set('order', this.order).subscribe(() => { });
      });
    } catch (error) {
      console.log(error);
    }
  }

  private _SetupUser(u: User = null) {
    if (u) {
      this.user = u;
    } else {
      this.user = this.authSvc.CurrentUser;
    }
    this.authCheck = this.authSvc.Authenticated;

    if (this.authCheck) {
      this._SetCheckoutDetails();
    }
  }

  private _SetCheckoutDetails() {
    // set delivery info
    // const da = (this.user.addresses || []).filter(a => { return a.isDefault; })[0] || <Address>{
    const da = this.user.addresses.filter(addr => addr.isDefault === true)[0] || {
      city: this.user.city,
      country: this.user.country,
      postalCode: this.user.postalCode,
      state: this.user.state,
      street1: this.user.street1,
      street2: this.user.street2,
      isDefault: this.user.addresses[0].isDefault
    } as Address;

    // const a = [this.user.street1, this.user.street2, this.user.city, this.user.state];
    const a = [da.street1, da.street2, da.city, da.state];
    this.deliveryInfo = new Info();
    this.deliveryInfo.orderType = this.order.ordertype;
    this.deliveryInfo.addressF = a.filter(s => s !== null && s !== undefined && s !== '').join(', ') + ` ${da.postalCode || ''}`;
    this.deliveryInfo.address = da;
    this.deliveryInfo.addresses = this.user.addresses;
    this.deliveryInfo.email = this.user.email;
    this.deliveryInfo.firstname = this.user.firstname;
    this.deliveryInfo.lastname = this.user.lastname;
    this.deliveryInfo.name = this.user.firstname + ' ' + this.user.lastname;
    this.deliveryInfo.phone = this.user.phone;
    // this.deliveryInfo.orderType = this.defaultOrderType.value;

    this.localStorage.set('deliveryinfo', this.deliveryInfo).subscribe((di: any) => { });
    // }

    if (this.deliveryInfo.name && this.deliveryInfo.email && this.deliveryInfo.phone) {
      this.isCustomerInfoValid = true;
    }

    // this.deliveryInfo.orderType = this.order.ordertype;

    if (this.deliveryInfo && this.deliveryInfo.orderType === OrderTypeEnum.Delivery) {
      this._cartSvc.setDeliveryPosition(this.deliveryInfo.addressF).then((result: any) => {
        if (result) {
          this.inRange = result.inRange;
          this.order.customerInformation.deliveryDistance = parseFloat(result.distance.toFixed(2));
          this.order.customerInformation.lat = result.lat;
          this.order.customerInformation.lng = result.lng;

          this.deliverySubscription = this.delService.getGeocode(this.deliveryInfo.addressF).subscribe(d => {
            if (d) {
              this.order.customerInformation.geolocation = {
                lat: d.lat,
                lng: d.lng,
              };
            }
          });

          // if (!result.inRange) {
          //   this.confirmDialog.open(ConfirmDialogComponent, {
          //     data: { message: 'Order Out of Range', text: 'Your order is out of our delivery range; please enter another delivery address or select another order option! We apologize for the inconvenience!' }
          //   });
          // }
        }

        // if (!result) {
        //   this.confirmDialog.open(ConfirmDialogComponent, {
        //     data: { message: 'Order Out of Range', text: 'Your order is out of our delivery range; please enter another delivery address or select another order option! We apologize for the inconvenience!' }
        //   });
        // }
      });
    }
  }

  async selectedMerchant(merchant: Merchant): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this.merchantInfo = merchant;
        this.tip = Object.assign(this.tip, this.merchantInfo.settings.tipSettings);
        this.tip.tipAmounts = this.tip.tipAmounts.filter(t => {
          if (t.enable && t.amount !== 'Custom') {
            t.amount = parseFloat(t.amount).toString();
            return t;
          }
        });
        if (this.tip.defaultIndex === 5) {
          this.selectedTip = 5;
          this.customTip = '0.00';
          this.SetTip(this.selectedTip, true);
        } else {
          if (this.merchantInfo.settings.tipSettings.defaultIndex > -1) {
            this.selectedTip = this.tip.tipAmounts.findIndex(a => a.amount === this.merchantInfo.settings.tipSettings.tipAmounts[this.merchantInfo.settings.tipSettings.defaultIndex].amount);
          } else {
            this.selectedTip = this.merchantInfo.settings.tipSettings.defaultIndex;
          }

          this.SetTip(this.selectedTip, false);
        }

        this._cartSvc.GetCart();

        resolve(undefined);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  private async _LoadMerchant(storename: string): Promise<Merchant> {
    return new Promise(async (resolve, reject) => {
      this._merchantSvc.getMerchantByRoute(storename).pipe(first()).subscribe(async (r: Merchant) => {
        if (r && r._id) {
          // create the cart for this merchant if it doesn't already exist:
          this.cartModel = await this._cartSvc.CreateCart(r);

          this.merchantInfo = r;
          this.tip = Object.assign(this.tip, r.settings.tipSettings);
          this.tip.tipAmounts = this.tip.tipAmounts.filter(t => {
            if (t.enable && t.amount !== 'Custom') {
              t.amount = parseFloat(t.amount).toString();
              return t;
            }
          });
          if (this.tip.defaultIndex === 5) {
            this.selectedTip = 5;
            this.customTip = '0.00';
            this.SetTip(this.selectedTip, true);
          } else {
            if (this.merchantInfo.settings.tipSettings.defaultIndex > -1) {
              this.selectedTip = this.tip.tipAmounts.findIndex(a => a.amount === this.merchantInfo.settings.tipSettings.tipAmounts[this.merchantInfo.settings.tipSettings.defaultIndex].amount);
            } else {
              this.selectedTip = this.merchantInfo.settings.tipSettings.defaultIndex;
            }

            this.SetTip(this.selectedTip, false);
          }

          this.orderTypes = r.orderOptions.filter(o => {
            if (o.enabled && !o.suspended) {
              if (o.value === OrderTypeEnum.Delivery) {
                this.defaultOrderType.text = o.desc;
                this.defaultOrderType.value = o.value;

                this.defaultOperationHours = r.settings.deliveryHours;
                this.waittime = r.settings.delWaittime ? r.settings.delWaittime : 0;
                // this.cartModel.deliverytype = o.value;
              } else {
                this.defaultOrderType.text = o.desc;
                this.defaultOrderType.value = o.value;
    
                this.defaultOperationHours = r.settings.pickupHours;
                this.waittime = r.settings.pickupWaittime ? r.settings.pickupWaittime : 0;
              }
              return o;
            }
          });

          if (this.defaultOrderType.text.trim() === '') {
            this.defaultOrderType.text = this.orderTypes[0].desc;
            this.defaultOrderType.value = this.orderTypes[0].value;

            this.defaultOperationHours = r.settings.pickupHours;
            this.waittime = r.settings.pickupWaittime ? r.settings.pickupWaittime : 0;
            // this.cartModel.deliverytype = this.orderTypes[0].value;
          }

          // format address:
          this.merchantAddress = ([
            this.merchantInfo.address,
            this.merchantInfo.city,
            this.merchantInfo.state].filter(s => {
              return s ? true : false;
            }).join(', ') + ` ${this.merchantInfo.zip}`).toUpperCase();

          this._cartSvc.GetCart();
          // this._LoadAllItems();
          resolve(r);
        } else {
          // no merchant found, redirect to... 404?
          reject();
          this._router.navigate(['/pagenotfound']).catch(() => {

          });
        }
      }, () => {
        reject();
      }, () => {
        // completed - do nothing?
      });
    });
  }

  public setNextAvailableTime() {
    // this.timer = setInterval(() => {
    // if (this.scheduleText && this.scheduleText !== null) {
    // console.log('checked ' + this.cartModel.schedule.value);
    if (this.cartModel && this.cartModel.schedule.asap) {
      // check if store closed -> move to next available date
      // const now = new Date().getTime();
      // const scheduledTime = new Date(this.cartModel.schedule.value).getTime();
      const submitTime = new Date().getTime() + (this.waittime * 60 * 1000);

      if (submitTime >= this.cartModel.schedule.close) {
        const confirmRef = this.confirmDialog.open(ConfirmDialogComponent, {
          data: { message: 'The store is already closed for the day! Your order will be schedule for the next available date and time!', text: '' }
        });

        confirmRef.afterClosed().pipe(first()).subscribe(r => {
          this.getNextAvailableDate();
          this._cartSvc.UpdateSchedule(this.cartModel.schedule);

          this._formatSchedule();
        });
      }
    } else {
      // check if passed time -> change to asap
      if (this.cartModel && this.cartModel.schedule.value > 0) {
        // const now = new Date().getTime();
        const scheduledTime = new Date(this.cartModel.schedule.value).getTime();
        const submitTime = new Date().getTime() + (this.waittime * 60 * 1000);
        // use < to test, use > for prod
        if (submitTime > scheduledTime) {
          this.cartModel.schedule.value += 5 * 60 * 1000;
          // this.cartModel.schedule.asap = true;
          if (this.cartModel.schedule.value > this.cartModel.schedule.close) {
            const confirmRef = this.confirmDialog.open(ConfirmDialogComponent, {
              data: { message: 'The store is already closed for the day!<br />Your order will be schedule for the next available date and time!', text: '' }
            });

            confirmRef.afterClosed().pipe(first()).subscribe(r => {
              this.getNextAvailableDate();
              this._cartSvc.UpdateSchedule(this.cartModel.schedule);

              this._formatSchedule();
            });
          } else {
            const confirmRef = this.confirmDialog.open(ConfirmDialogComponent, {
              data: { message: 'It\'s already passed the time that you want to schedule your order for!<br />Your order will be schedule for ASAP!', text: '' }
            });

            confirmRef.afterClosed().pipe(first()).subscribe(r => {
              this.cartModel.schedule.asap = true;
              this._cartSvc.UpdateSchedule(this.cartModel.schedule);

              this._formatSchedule();
            });
          }
          this._cartSvc.UpdateSchedule(this.cartModel.schedule);

          this._formatSchedule();
        }
      }
    }
    // }, 300000);
  }

  public getNextAvailableDate() {
    let open: any;
    let invalidDay = true;
    const t = new Date();

    while (invalidDay) {
      // t.setDate(t.getDate() + 1);
      const openTime = this.defaultOperationHours[t.getDay()].openHour.split(':');
      const closeTime = this.defaultOperationHours[t.getDay()].closeHour.split(':');

      if (openTime.length > 1 && closeTime.length > 1) {
/*         if (openTime[1].indexOf('PM') !== -1) {
          open = t.setHours(parseInt(openTime[0].trim(), 10) + 12, parseInt(openTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
        } else {
          open = t.setHours(parseInt(openTime[0].trim(), 10), parseInt(openTime[1].trim(), 10), 0, 0); // this.merchantInfo.settings.openTime - needs to be a 24 hour digit (0-23)
        } */

        open = t.setHours(parseInt(openTime[0].trim(), 10), parseInt(openTime[1].trim(), 10), 0, 0);

        this.cartModel.schedule.text = ScheduleType.SCHEDULED;
        this.cartModel.schedule.value = open + (this.waittime * 60000);
        this.cartModel.schedule.asap = false;

        invalidDay = false;
      }

      // if (openTime.length === 1 || closeTime.length === 1 || d.getTime() > close) {
      //   d = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
      // } else {
      //   invalidDay = false;
      // }
    }
  }

  private _formatSchedule() {
    // if (this.cartModel.schedule.value === 0) {
    if (this.cartModel.deliverytype === -1) {
      this.scheduleText = 'Set your <u>' + this.defaultOrderType.text + '</u> Option';
      this.orderType = this.defaultOrderType.text;
      this.order.ordertype = this.defaultOrderType.value;

      if (this.order.ordertype === OrderTypeEnum.PickUp) {
        this.addressTitle = 'Pickup at:';
      } else if (this.order.ordertype === OrderTypeEnum.DineIn) {
        this.addressTitle = 'Dine In at:';
      } else {
        this.addressTitle = 'To be Delivered to:';
      }
    } else {
      try {
        this.orderType = this.cartModel.deliverytypetext;
        this.order.ordertype = this.cartModel.deliverytype;

        this.order.datetime = this.scheduleText;
        this.localStorage.set('order', this.order).subscribe(() => { });


        if (this.cartModel.deliverytype !== OrderTypeEnum.Delivery) {
          this.inRange = true;
          // } else {
          // this.setDeliveryPosition();
        }

        /*         if (this.cartModel.schedule.asap) {
                  this.scheduleText = 'ASAP';
                } else { */
        let readyTime: any;
        if (this.cartModel.schedule.value > 0) {
          readyTime = new Date(this.cartModel.schedule.value);
        } else {
          // Getting Operation Hours
          const date = new Date();
          const dayOfWeek = date.getDay(); // 0-6 -> Sunday - Saturday
          const currentTime = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
          const current = Number(currentTime.replace(':', ''));
          let operationHour: OperationHours;
          if (this.cartModel.deliverytype === OrderTypeEnum.Delivery) {
            operationHour = this.merchantInfo.settings.deliveryHours[dayOfWeek];
          } else {
            operationHour = this.merchantInfo.settings.pickupHours[dayOfWeek];
          }

          let openTime: any;
          let closeTime: any;

          if (operationHour.openHour) {
            openTime = Number(operationHour.openHour.replace(':', '').replace('AM', '').replace('PM', ''));
            closeTime = Number(operationHour.closeHour.replace(':', '').replace('AM', '').replace('PM', ''));
          }

          if (operationHour && operationHour.openHour && operationHour.closeHour && operationHour.openHour.trim() !== '' && operationHour.closeHour.trim() !== '') {
            if ((operationHour.closeHour.indexOf('PM') !== -1 && operationHour.closeHour.substr(0, 2) !== '12')) { // from 1:00 PM to 12:00 AM
              closeTime += 1200; // Convert from 12 hours format to 24 hours format
            }
            if ((operationHour.openHour.indexOf('PM') !== -1 && operationHour.openHour.substr(0, 2) !== '12')) { // from 1:00 PM to 12:00 AM
              openTime += 1200; // Convert from 12 hours format to 24 hours format
            }

            if (operationHour.available === false || openTime >= closeTime || !this.merchantInfo.settings.Active) {
              this.isClosed = true;
            } else if (current < openTime || current > closeTime) {
              // Closed
              this.isClosed = true;
            } else {
              // Open
              this.isClosed = false;
            }
          } else {
            this.isClosed = true;
          }

          if (this.isClosed) {
            this.cartModel.schedule.value = 0;
            this.getNextAvailableDate();
          } else {
            readyTime = new Date(new Date().getTime() + (this.waittime + 1) * 60 * 1000);
            this.cartModel.schedule.value = readyTime;
          }
        }

        this.scheduleText = (this.cartModel.schedule.text === ScheduleType.ASAP)
          ? this.isClosed ? 'Please Select Time For Your Order.'
          : 'ASAP - Today ' + moment(readyTime).format('h:mm a')
          : moment(readyTime).format('ddd, MMMM Do - h:mm a');
          
        this.orderType = this.cartModel.deliverytypetext;

        if (this.orderType === 'Pickup') {
          this.addressTitle = 'Pickup at:';
        } else if (this.orderType === 'Dine In') {
          this.addressTitle = 'Dine In at:';
        } else {
          this.addressTitle = 'To be Delivered to:';
        }

        // this._cartSvc.UpdateSchedule(this.cartModel.schedule);
      } catch (e) {
        this.scheduleText = 'ASAP - Approximately 30 minutes';
      }
    }
  }

  private _SetItemExtras() {
    // only grab the selected items for the cart to display:
    this.item.sides = this.modalItem.sides.filter(i => i.selected);
    this.item.options = this.modalItem.options.filter(i => i.selected);
    this.item.modifiers = this.modalItem.modifiers.filter(i => i.selected);
  }

  public CategoryClicked(cid: string) {
    this.CategoryButtonClicked = true;
    this.selectedCategory = cid;
    this.categoryItems = null;
    const catItems = this.itemCache.filter(icr => {
      return cid === '' || icr.categoryid === cid;
    });
    if (catItems.length > 0) {
      this.categoryItems = catItems;
    }
  }

  public SubCategoryClicked(c: Category) {
    this.selectedSubCategory = c;
    const catItems = this.itemCache.filter(icr => {
      return icr.categoryid === c._id;
    });
    if (catItems[0]) {
      this.categoryItems = catItems;
      // this._ScrollToCategory();
    } else {
      this._itemSvc.getCategoryItems('s', `${c.categoryid}`, this.merchantInfo._id).subscribe((r: Item[]) => {
        r = r.sort((a, b) => {
          return a.title > b.title ? 1 : -1;
        });
        const ic = {
          categoryid: c._id,
          category: c.title || c.desc,
          items: r
        };
        this.itemCache.push(ic);
        this.categoryItems = [ic];
        // this._ScrollToCategory();
      }, (e) => {
        // error
        console.warn(e);
      }, () => {
        // complete - do nothing
      });
    }
  }

  public SearchAllItems() {
    this.CategoryButtonClicked = false;
    const stlower = this.searchText.toLowerCase();
    const results: ItemCacheRecord[] = [];
    this.itemCache.forEach(ic => {
      const catResults = ic.items.filter(i => {
        return i.title.toLowerCase().indexOf(stlower) !== -1 || i.desc.toLowerCase().indexOf(stlower) !== -1;
      });
      if (catResults.length > 0) {
        results.push({
          category: ic.category,
          categoryid: ic.categoryid,
          items: catResults
        });
      }
    });
    this.categoryItems = results;
  }

  public SetDeliveryType(value: any) {
    this._cartSvc.UpdateDeliveryType(value);
  }

  public UpdateItem(item: Item, idx: number) {
    if (item.qty === 0) {
      this.RemoveItem(idx);
    } else {
      this._SetItemExtras();
      this._cartSvc.UpdateItem(item, idx);
    }
  }

  public RemoveItem(idx: number) {
    const i = this.cartModel.itemList[idx];

    const confirmRef = this.confirmDialog.open(ConfirmDialogComponent, {
      data: { message: `Are you sure you want to remove ${i.title} from your cart?`, text: '' }
    });

    confirmRef.afterClosed().pipe(first()).subscribe(r => {
      if (r) {
        this._cartSvc.RemoveItem(idx);
      }
    });

  }

  public OpenLoginDialog() {
    // open popup with item details?
    const dialogRef = this._loginDialog.open(LoginDialogComponent, {
      width: '300px'
    });

    dialogRef.afterClosed().pipe(first()).subscribe(r => {
      if (r === 'register') {
        this.OpenRegistrationDialog();
      }
    });
  }

  public OpenRegistrationDialog() {
  }

  public Logout() {
    this.authSvc.logout();
  }

  public ScheduleDialog() {
    // try {
      const orderTypeDescValue = this.cartModel.deliverytypetext;

      if (this._scheduleDialog.openDialogs.length === 0) {
        const dRef = this._scheduleDialog.open(ScheduleDialogComponent, {
          data: {
            orderType: this.cartModel.deliverytype >= 0 ? this.cartModel.deliverytype : this.defaultOrderType.value,
            orderTypeDesc: orderTypeDescValue,
            schedule: this.cartModel.schedule,
            merchant: this.merchantInfo
          } as IScheduleDialogData,
          scrollStrategy: this.overlay.scrollStrategies.block(),
          width: '350px'
        });

        dRef.afterClosed().pipe(first()).subscribe((r: ISchedule) => {
          if (r) {
            if (this.cartModel.deliverytype === OrderTypeEnum.Delivery) {
              this.defaultOperationHours = this.merchantInfo.settings.deliveryHours;
              this.waittime = this.merchantInfo.settings.delWaittime ? this.merchantInfo.settings.delWaittime : 20;
            } else {
              this.defaultOperationHours = this.merchantInfo.settings.pickupHours;
              this.waittime = this.merchantInfo.settings.pickupWaittime ? this.merchantInfo.settings.pickupWaittime : 20;
            }

            this.cartModel.schedule = r;
            this._cartSvc.UpdateSchedule(this.cartModel.schedule);
            this._formatSchedule();

            if (this.cartModel.deliverytype === OrderTypeEnum.Delivery) {
              this._cartSvc.setDeliveryPosition(this.deliveryInfo.addressF).then((result: any) => {
                if (result) {
                  this.inRange = result.inRange;
                  this.order.customerInformation.deliveryDistance = parseFloat(result.distance.toFixed(2));
                  this.order.customerInformation.lat = result.lat;
                  this.order.customerInformation.lng = result.lng;

                  this.deliverySubscription = this.delService.getGeocode(this.deliveryInfo.addressF).subscribe(d => {
                    if (d) {
                      this.order.customerInformation.geolocation = {
                        lat: d.lat,
                        lng: d.lng,
                      };
                    }
                  });

                  if (!result.inRange) {
                    this.confirmDialog.open(ConfirmDialogComponent, {
                      data: { message: 'Order Out of Range', text: 'Your order is out of our delivery range; please enter another delivery address or select another order option! We apologize for the inconvenience!' }
                    });
                  }
                }
              });
            }

            this.order.ordertype = this.cartModel.deliverytype;
            // this.orderType = OrderTypeEnum[this.order.ordertype];
            this.localStorage.set('schedule', r).subscribe(() => { });
          }
        });
      }
    // } catch (error) {
    //   console.log(error.message);
    // }
  }

  public ValidateItemQty(change: number = 0) {
    if (change !== 0) { this.item.qty += change; }
    this.item.qty = this.item.qty > this.item.maxqty ? this.item.maxqty : this.item.qty;
    this.item.qty = this.item.qty < 1 ? 1 : this.item.qty;
  }

  public Add() {
    this._SetItemExtras();
    // add the item:
    this._cartSvc.AddItem(this.item);
  }

  get sidesChosen() {
    return this.modalItem.sides.filter((s) => s.selected).length;
  }

  get sidesPrice() {
    // i.sides.map(s => s.price).reduce((p, c) => { return p + (c || 0); }, 0);
    return this.modalItem.sides.filter((s) => s.selected).map(s => s.price).reduce((p, c) => {
      return p + (c || 0);
    }, 0);
  }

  get scheduledReadyTime() {
    return this.sanitizer.bypassSecurityTrustHtml(this.scheduleText);
  }

  // new for checkout:
  public AddressDialog() {
    if (!this.deliveryInfo) {
      this.deliveryInfo = new Info();
      // this.deliveryInfo.orderType = (this.cartModel.deliverytype) ? this.cartModel.deliverytype : this.defaultOrderType.value;
    }

    this.deliveryInfo.orderType = (this.cartModel.deliverytype) ? this.cartModel.deliverytype : this.defaultOrderType.value;
    this.order.useWalletShippingAddress = false;

    if (this._addressDialog.openDialogs.length === 0) {
      const dref = this._addressDialog.open(AddressDialogComponent, {
        scrollStrategy: this.overlay.scrollStrategies.block(),
        data: this.deliveryInfo,
        maxWidth: '500px'
      });

      dref.afterClosed().pipe(first()).subscribe((r: Info) => {
        if (r) {
          this.deliveryInfo = r;

          this.isCustomerInfoValid = true;

          if (this.cartModel.deliverytype === OrderTypeEnum.Delivery) {
            this._cartSvc.setDeliveryPosition(r.addressF).then((result: any) => {
              if (result) {
                this.inRange = result.inRange;
                this.order.customerInformation.deliveryDistance = parseFloat(result.distance.toFixed(2));
                this.order.customerInformation.lat = result.lat;
                this.order.customerInformation.lng = result.lng;

                if (!result.inRange) {
                  this.confirmDialog.open(ConfirmDialogComponent, {
                    data: { message: 'Order Out of Range', text: 'Your order is out of our delivery range; please enter another delivery address or select another order option! We apologize for the inconvenience!' }
                  });
                } else {
                  if (this.cartModel.deliverytype === OrderTypeEnum.Delivery) {
                    this.deliverySubscription = this.delService.getGeocode(this.deliveryInfo.addressF).subscribe(d => {
                      if (d) {
                        this.order.customerInformation.geolocation = {
                          lat: d.lat,
                          lng: d.lng,
                        };
                      }
                    });
                  }
                }
              }
            });
          }
          // this.setDeliveryPosition();

          // this.order.customerInformation.name = this.deliveryInfo.name;
          // this.order.customerInformation.firstName = this.deliveryInfo.firstname;
          // this.order.customerInformation.lastName = this.deliveryInfo.lastname;
          // this.order.customerInformation.email = this.deliveryInfo.email;
          // this.order.customerInformation.phone = this.deliveryInfo.phone;

          // if (this.order.ordertype === OrderTypeEnum.Delivery) {
          //   this.order.customerInformation.street1 = this.deliveryInfo.address.street1;
          //   this.order.customerInformation.street2 = this.deliveryInfo.address.street2 || '';
          //   this.order.customerInformation.city = this.deliveryInfo.address.city;
          //   this.order.customerInformation.state = this.deliveryInfo.address.state;
          //   this.order.customerInformation.postalcode = this.deliveryInfo.address.postalCode;
          //   this.order.customerInformation.country = this.deliveryInfo.address.country || 'USA';
          //   this.order.customerInformation.geolocation = this.deliveryInfo.address.geolocation;
          // }

          // this.localStorage.set('deliveryinfo', this.deliveryInfo).subscribe(() => {
          //   this.localStorage.set('order', this.order).subscribe(() => { });
          // });
        }
      });
    }
  }

  public PaymentDialog() {
    if (this._paymentDialog.openDialogs.length === 0) {
      const dref = this._paymentDialog.open(PaymentDialogComponent, {
        data: { order: this.order, address: this.deliveryInfo, cardLength: this.paymentMethod && this.paymentMethod.cardnumber ? this.paymentMethod.cardnumber.length : 0 },
        disableClose: false,
        height: '600px',
        minWidth: 'calc(30vw)',
        maxWidth: 600,
        width: '500px',
      });

      dref.afterClosed().pipe(first()).subscribe((r: CardInfo) => {
        if (r) {
          this.isPaymentInfoValid = true;
          this.paymentMethod = r;
          this.localStorage.set('paymethod', r).subscribe(() => { });
        }
      });
    }
  }

  public SetTip(index: number, isCustom: boolean) {
    this.selectedTip = index;

    if (this.tip.enable) {
      if (isCustom) {
        this.cartModel.tip.Type = TipType.Flat;
        this.cartModel.tip.Percent = -1;
        this.cartModel.tip.Flat = parseFloat(this.customTip.replace(/,/gi, ''));
      } else {
        if (index > -1) {
          // } else {
          if (this.tip.isPercentage) {
            this.cartModel.tip.Type = TipType.Percent;
            this.cartModel.tip.Percent = parseFloat(this.tip.tipAmounts[index].amount) / 100;
            this.cartModel.tip.Flat = 0;
            this.customTip = '';

            const totalTip = (this.cartModel.tip.Percent / 100) * this.cartModel.subtotal.Value;
            if (totalTip > parseFloat(this.tip.maxAmount)) {
              this.cartModel.tip.Type = TipType.Flat;
              this.cartModel.tip.Percent = -1;
              this.cartModel.tip.Flat = parseFloat(this.tip.maxAmount.replace(/,/gi, ''));
              this.customTip = '';
            }
          } else {
            this.cartModel.tip.Type = TipType.Flat;
            this.cartModel.tip.Percent = -1;
            this.cartModel.tip.Flat = parseFloat(this.tip.tipAmounts[index].amount.replace(/,/gi, ''));
            this.customTip = '';
          }
        } else {
          this.cartModel.tip.Type = TipType.Percent;
          this.cartModel.tip.Percent = 0;
          this.cartModel.tip.Flat = 0;
          this.customTip = '';
        }
      }
    } else {
      this.cartModel.tip.Type = TipType.Percent;
      this.cartModel.tip.Percent = 0;
      this.cartModel.tip.Flat = 0;
      this.customTip = '';
    }

    // switch (type) {
    //   case 'p':
    //     this.cartModel.tip.Type = TipType.Percent;
    //     this.cartModel.tip.Percent = parseFloat(value);
    //     this.cartModel.tip.Flat = 0;
    //     this.customTip = '';
    //     break;
    //   default:
    //     this.cartModel.tip.Type = TipType.Flat;
    //     this.cartModel.tip.Percent = -1;
    //     this.cartModel.tip.Flat = parseFloat(this.customTip.replace(/,/gi, ''));
    //     break;
    // }

    this.localStorage.set(this.merchantInfo._id, this.cartModel).subscribe(() => {
      this.localStorage.set('order', this.order).subscribe(() => {
        this.localStorage.set('tip', this.cartModel.tip).subscribe(() => {
          this._cartSvc.UpdateTip(this.cartModel.tip);
        });
      });
    });
  }

  public validateTipMax() {
    const maxAmount = parseFloat(this.merchantInfo.settings.tipSettings.maxAmount);
    const custTip = parseFloat(this.customTip.replace(',', ''));

    if (custTip > maxAmount) {
      const dref = this.confirmDialog.open(ConfirmDialogComponent, {
        data: { message: 'Tip Amount Entered Exceed The Maximum Acceptable Tip Amount', text: '', okonly: true }
      });

      dref.afterClosed().pipe(first()).subscribe(r => {
        this.customTip = maxAmount.toFixed(2);
        this.cartModel.tip.Flat = maxAmount;
        this.tipFocusOut();
      });
    }
  }

  public tipFocusOut() {
    if (!this.customTip || this.customTip.trim() === '' || this.cartModel.tip.Flat === 0) {
      this.cartModel.tip.Type = TipType.Percent;
      this.cartModel.tip.Percent = 0;
      this.cartModel.tip.Flat = 0;
      this.customTip = '';
    } else {
      this.customTip = this.cartModel.tip.Flat.toFixed(2);
    }

    this._cartSvc.UpdateTip(this.cartModel.tip);
    this.localStorage.set('tip', this.cartModel.tip).subscribe(() => { });
  }

  get TipType() {
    return TipType;
  }

  public displayDialog(titleText: string, message: string): void {
    try {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: { title: titleText, text: message }
      });

      dialogRef.afterClosed().subscribe(() => {
        return;
      });
      // When the user clicks anywhere outside of the modal, close it

    } catch (error) {
      console.log(error);
    }
  }

  public isValidOrder(): boolean {
    try {
      if (!this.order) {
        this.displayDialog('Checkout', 'Order object is undefined!');
        return false;
      }

      if (!this.order.items) {
        this.displayDialog('Checkout', 'No items have been added!');
        return false;
      }

      if (this.order.items.length === 0) {
        this.displayDialog('Checkout', 'No items have been added!');
        return false;
      }

      if (this.order.ordertype === OrderTypeEnum.Delivery || this.order.ordertype === OrderTypeEnum.PickUp) {
        if (!this.order.paymethods) {
          this.displayDialog('Checkout', 'No pay methods have been added!');
          return false;
        }

        if (this.order.paymethods.length === 0) {
          this.displayDialog('Checkout', 'No pay methods have been added!');
          return false;
        }

        if (this.order.paymethods[0].amount === 0) {
          this.displayDialog('Checkout', 'Order amount must be greater than zero!');
          return false;
        }

        if (!this.order.paymethods[0].cardnumber) {
          this.displayDialog('Checkout', 'No card number has been entered!');
          return false;
        }

        if (!this.order.paymethods[0].cc_exp_month) {
          this.displayDialog('Checkout', 'Expiration month is missing!');
          return false;
        }

        if (!this.order.paymethods[0].cc_exp_year) {
          this.displayDialog('Checkout', 'Expiration year is missing!');
          return false;
        }

        if (!this.order.paymethods[0].cvv) {
          this.displayDialog('Checkout', 'CVV is missing!');
          return false;
        }
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  public async GuestLogin(): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {

        const credentials = new LogIn();
        credentials.email = 'guest@electronicpayments.com';
        credentials.password = '';
        credentials.application = 'deliverme';

        this.authSvc.guestLogin(credentials).subscribe(async (data) => {

          if (data && data.access_token) {
            this.user = data;
            this.localStorage.set('user', this.user).subscribe(() => { });
            localStorage.setItem('token', data.access_token); // this is not the same as 'this.localStorage'
            this.localStorage.set('token', data.access_token).subscribe(() => { });
            resolve(data);
          } else {
            this.localStorage.delete('token').subscribe(() => { });
            localStorage.delete('token');
            reject({ message: 'Authorization token not generated' });
          }
        }, err => {
          if (err.error) {
            reject(err.error);
          } else {
            reject(err);
          }
        });
      } catch (error) {
        reject(error);
      }
    });

  }

  public PlaceOrder(stepper: MatStepper) {

    this.processing.inprogress = true;
    let logEntry = new LogEntry();

    logEntry.entry.application = 'dm-ui';
    logEntry.entry.file = 'store-checkout-component.ts';
    logEntry.entry.method = 'PlaceOrder';
    logEntry.entry.merchantNumber = (this.merchantInfo && this.merchantInfo.merchantNumber1) ? this.merchantInfo.merchantNumber1 : '';
    logEntry.entry.text = "Placing new order";

    if (this.loggerSubscription) {
      this.loggerSubscription.unsubscribe();
    }

    this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

    }, (error: any) => {
      console.log('log service error: ' + error.message);
    });

    // check that the scheduled time is while the store is still open
    // and that the scheduled time doesn't need to be rescheduled:
    const now = new Date().getTime();
    const scheduledTime = new Date(this.cartModel.schedule.value).getTime();
    const submitTime = now + (this.waittime * 60 * 1000);
    // use < to test, use > for prod

    if (this.cartModel.deliverytype === OrderTypeEnum.Delivery && !this.order.customerInformation.geolocation) {
      this.isCustomerInfoValid = false;
      const dref = this.confirmDialog.open(ConfirmDialogComponent, {
        data: { message: `Invalid delivery address! Please set a valid delivery address before continuing your order`, text: '' }
      });

      dref.afterClosed().pipe(first()).subscribe(r => {
        if (r) {
          this.AddressDialog();
        }
      });

      this.processing.inprogress = false;
      return;
    } else {
      this.isCustomerInfoValid = true;
    }

    if (!this.inRange) {
      const dref = this.confirmDialog.open(ConfirmDialogComponent, {
        data: { message: 'Your order is out of our delivery range; please enter another delivery address! We apologize for the inconvenience!', text: '' }
      });

      dref.afterClosed().pipe(first()).subscribe(r => {
        if (r) {
          this.AddressDialog();
        }
      });

      this.processing.inprogress = false;
      return;
    }

    if (submitTime > scheduledTime) {
      // alert that the order needs to be rescheduled:
      // let newordertime = now + (parseInt(`${this.cartModel.schedule.wait}`) * 60 * 1000);
      // let notDate = new Date(newordertime);
      // let newordertimetext = `${notDate.toLocaleDateString('default', this.dateFormatOptions)} at
      //                          ${notDate.toLocaleTimeString('default', this.timeFormatOptions)}`;
      const dref = this.confirmDialog.open(ConfirmDialogComponent, {
        data: { message: `The kitchen will need more time to complete your order. Please set a new order time before continuing your order`, text: '' }
      });

      dref.afterClosed().pipe(first()).subscribe(r => {
        if (r) {
          this.ScheduleDialog();
        }
      });

      this.processing.inprogress = false;
      return;
    }

    let itemid = '';
    let text = '';
    let msg = '';
    let text86 = '';
    const lowItems = [];
    this.cartModel.itemList.forEach((i: Item) => {
      itemid += i.itemid.toString() + ',';
    });
    itemid = itemid.substring(0, itemid.length - 1);

    if (itemid.trim() !== '') {
      if (this.merchantInfo.rmsType !== 3 && this.merchantInfo.apiUrl) {

        logEntry.entry.text = "getItemsInventory";

        this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

        }, (error: any) => {
          console.log('log service error: ' + error.message);
        });

        this.itemSubscription = this._itemSvc.getItemsInventory(itemid, this.merchantInfo._id).subscribe(async (items: Item[]) => {

          logEntry.entry.text = "items count: " + items.length.toString();

          this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

          }, (error: any) => {
            console.log('log service error: ' + error.message);
          });

          items.forEach(async i => {
            const checkItem = this.cartModel.itemList.find(item => item.itemid === i.itemid);

            if (i.item86) {
              text86 += (text86.length > 0 ? ', ' : '') + i.title;
              this._cartSvc.RemoveItem(this.cartModel.itemList.indexOf(checkItem));
            } else if (i.inventoryTracked) {
              if (i.qtyOnHand < checkItem.qty) {
                if (i.qtyOnHand <= 0) {
                  this._cartSvc.RemoveItem(this.cartModel.itemList.indexOf(checkItem));
                } else {
                  i.qty = i.qtyOnHand;
                  text += (text.length > 0 ? ', ' : '') + checkItem.title + ' is ' + i.qtyOnHand;
                  this._cartSvc.UpdateItem(i, this.cartModel.itemList.indexOf(checkItem));
                }
              } else {
                i.qty = checkItem.qty;
              }

              if (i.qtyOnHand - i.qty < i.lowLevelQty) {
                msg += (msg.length > 0 ? ', ' : '') + checkItem.title;
                lowItems.push(checkItem);
              }
            }
            // checkItem.qty = i.qtyOnHand;
          });

          if (text86.trim() !== '') {
            const confirmRef1 = this.confirmDialog.open(ConfirmDialogComponent, {
              data: { message: `${text86} is/are currently not available and will be removed from your cart. We're sorry for the inconvenience!`, text: '', yesno: false, okonly: true, }
            });
          }

          if (text.trim() !== '') {
            text.substring(0, text.length - 2);
            const confirmRef1 = this.confirmDialog.open(ConfirmDialogComponent, {
              data: { message: `Maximum quantity available for ${text}`, text: '', yesno: false, okonly: true, }
            });
          }

          if (lowItems.length > 0) {
            msg.substring(0, msg.length - 2);
            const message = {
              userid: this.user._id,
              merchantid: this.merchantInfo._id,
              to_numbers: [(this.merchantInfo.contactInfo && this.merchantInfo.contactInfo.phone) ? this.merchantInfo.contactInfo.phone : this.merchantInfo.phone],
              storename: this.merchantInfo.businessName,
              type: 0,
              message: 'The quantity in stock for ' + msg + (msg.indexOf(',') > -1 ? ' are low' : ' is low')
            };

            if (!this.merchantInfo.contactInfo ||
              this.merchantInfo.contactInfo.contactMethod === null ||
              this.merchantInfo.contactInfo.contactMethod === undefined ||
              this.merchantInfo.contactInfo.contactMethod === 1 ||
              this.merchantInfo.contactInfo.contactMethod === 3) {
              this.smsSubscription = this._smsSvc.sendMessage(message).subscribe();
            }

            if (!this.merchantInfo.contactInfo ||
              this.merchantInfo.contactInfo.contactMethod === null ||
              this.merchantInfo.contactInfo.contactMethod === undefined ||
              this.merchantInfo.contactInfo.contactMethod > 1) {

              const email: EmailOrderConfirmation = new EmailOrderConfirmation();
              const template: any = {
                items: lowItems
              };

              email.Source = 'support@deliverme.awsapps.com';
              email.Template = 'low_quantity';
              email.Destination.ToAddresses = [(this.merchantInfo.contactInfo && this.merchantInfo.contactInfo.email) ? this.merchantInfo.contactInfo.email : this.merchantInfo.email];
              email.Destination.CcAddresses = [];
              email.TemplateData = JSON.stringify(template);

              if (this.merchantEmailSubscription) {
                this.merchantEmailSubscription.unsubscribe();
              }

              this.merchantEmailSubscription = this.emailService.sendEmailConfirmation(email).subscribe((res: any) => {
              }, (err: any) => {
                console.log(err);

                logEntry.entryType = 'Error';
                logEntry.entry.text = (err.error) ? err.error.message : (err.message) ? err.message : 'error ocurred sending email';

                this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

                }, (error: any) => {
                  console.log('log service error: ' + error.message);
                });

              });
            }
          }

          if (this.cartModel.itemList.length > 0 && this.cartModel.subtotal.Value < this.merchantInfo.settings.minorderamt) {
            const confirmRef2 = this.confirmDialog.open(ConfirmDialogComponent, {
              data: { message: `Your order is below the Minimum Order Amount. Please add more item to continue!`, text: '', yesno: false, okonly: true, }
            });

            confirmRef2.afterClosed().subscribe(() => {
              this._router.navigate([this.merchantInfo.routeName]).catch(e => { });
              this.processing.inprogress = false;
              return;
            });
          } else {
            this.submitOrder(stepper);
          }

          // this.confirmRef.afterClosed().pipe(first()).pipe(
          //   finalize(() => this.confirmRef = undefined)
          // ).subscribe(r => {
          //   this.cartModel.itemList[i].qty = checkItem.qtyOnHand;
          //   this._cartSvc.UpdateItem(this.cartModel.itemList[i], i);
          // });
        });
      } else {
        this.submitOrder(stepper);
      }
    }

    // this.submitOrder();
  }

  public onStepChange(event: any): void {
    // console.log(event);
    this.stepperIndex = event.selectedIndex;

    if (this.stepperIndex === 1) {

    }
  }

  async submitOrder(stepper: MatStepper) {

    let logEntry = new LogEntry();

    try {

      logEntry.entry.application = 'dm-ui';
      logEntry.entry.file = 'store-checkout-component.ts';
      logEntry.entry.method = 'submitOrder';
      logEntry.entry.merchantNumber = (this.merchantInfo && this.merchantInfo.merchantNumber1) ? this.merchantInfo.merchantNumber1 : '';
      logEntry.entry.text = "submitting order";

      if (this.loggerSubscription) {
        this.loggerSubscription.unsubscribe();
      }

      this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

      }, (error: any) => {
        console.log('log service error: ' + error.message);
      });

      this.order.requestID = uuid();

      if (!this.order.deviceInfo) {
        this.order.deviceInfo = new DeviceDetails();
      }

      this.order.deviceInfo.deviceInfo = this.deviceService.getDeviceInfo();
      this.order.deviceInfo.isMobile = this.deviceService.isMobile();
      this.order.deviceInfo.isTablet = this.deviceService.isTablet();
      this.order.deviceInfo.isDeskTopService = this.deviceService.isDesktop();

      this.order.applicationKey = this.merchantInfo.applicationKey;
      this.order.merchantNumber = this.merchantInfo.merchantNumber1;
      this.order.businessName = this.merchantInfo.businessName;

      let customerName: any;

      switch (this.cartModel.deliverytype) {
        case OrderTypeEnum.Delivery:
          this.order.customerInformation.name = this.deliveryInfo.name;

          customerName = this.deliveryInfo.name.split(' ');

          switch (customerName.length) {
            case 2:
              this.order.customerInformation.firstName = this.deliveryInfo.name.split(' ')[0];
              this.order.customerInformation.lastName = this.deliveryInfo.name.split(' ')[1];
              break;
            case 3:
              this.order.customerInformation.firstName = this.deliveryInfo.name.split(' ')[0];
              this.order.customerInformation.lastName = this.deliveryInfo.name.split(' ')[2];
              break;
          }

          this.order.customerInformation.firstName = this.deliveryInfo.firstname;
          this.order.customerInformation.lastName = this.deliveryInfo.lastname;
          this.order.customerInformation.email = this.deliveryInfo.email;
          this.order.customerInformation.phone = this.deliveryInfo.phone;
          this.order.customerInformation.street1 = this.deliveryInfo.address.street1;
          this.order.customerInformation.street2 = this.deliveryInfo.address.street2 || '';
          this.order.customerInformation.city = this.deliveryInfo.address.city;
          this.order.customerInformation.state = this.deliveryInfo.address.state;
          this.order.customerInformation.postalcode = this.deliveryInfo.address.postalCode;
          this.order.customerInformation.country = this.deliveryInfo.address.country || 'USA';
          break;
        case OrderTypeEnum.PickUp:
          this.order.customerInformation.name = this.deliveryInfo.name;

          customerName = this.deliveryInfo.name.split(' ');

          switch (customerName.length) {
            case 2:
              this.order.customerInformation.firstName = this.deliveryInfo.name.split(' ')[0];
              this.order.customerInformation.lastName = this.deliveryInfo.name.split(' ')[1];
              break;
            case 3:
              this.order.customerInformation.firstName = this.deliveryInfo.name.split(' ')[0];
              this.order.customerInformation.lastName = this.deliveryInfo.name.split(' ')[2];
              break;
          }

          this.order.customerInformation.email = this.deliveryInfo.email;
          this.order.customerInformation.phone = this.deliveryInfo.phone;
          this.order.customerInformation.street1 = this.deliveryInfo.address.street1;
          this.order.customerInformation.street2 = this.deliveryInfo.address.street2 || '';
          this.order.customerInformation.city = this.deliveryInfo.address.city;
          this.order.customerInformation.state = this.deliveryInfo.address.state;
          this.order.customerInformation.postalcode = this.deliveryInfo.address.postalCode;
          this.order.customerInformation.country = this.deliveryInfo.address.country || 'USA';
          this.order.customerInformation.deliveryDistance = 0;
          break;
        default:

          customerName = this.paymentMethod.name.split(' ');

          switch (customerName.length) {
            case 2:
              this.order.customerInformation.firstName = this.paymentMethod.name.split(' ')[0];
              this.order.customerInformation.lastName = this.paymentMethod.name.split(' ')[1];
              break;
            case 3:
              this.order.customerInformation.firstName = this.paymentMethod.name.split(' ')[0];
              this.order.customerInformation.lastName = this.paymentMethod.name.split(' ')[2];
              break;
          }

          this.order.customerInformation.street1 = this.paymentMethod.street1;
          this.order.customerInformation.street2 = '';
          this.order.customerInformation.city = this.paymentMethod.city;
          this.order.customerInformation.state = this.paymentMethod.state;
          this.order.customerInformation.postalcode = this.paymentMethod.zip;
          break;
      }

      // user should either be guest or their account:
      this.order.userid = this.user._id;
      this.order.datetime = moment(this.cartModel.schedule.value).format('LLL');
      this.order.ordertype = this.cartModel.deliverytype;
      this.order.asap = this.cartModel.schedule.asap;

      // console.log(this.cartModel.schedule.value.toString());
      // make a new copy of the itemList:
      this.order.items = JSON.parse(JSON.stringify(this.cartModel.itemList));

      try {
        this.order.tip = parseFloat(this.cartModel.tip.Value.toFixed(2)); // Tri - Replacing Tip Percent with Actual Tip Amount
        this.order.serviceFee = parseFloat(this.cartModel.servicefee.Value.toFixed(2));
        this.order.convenienceFee = parseFloat(this.cartModel.conveniencefee.Value.toFixed(2));
        this.order.deliveryFee = parseFloat(this.cartModel.deliveryfee.Value.toFixed(2));
        this.order.tax = parseFloat(this.cartModel.tax.Value.toFixed(2));
        this.order.subtotal = parseFloat(this.cartModel.subtotal.Value.toFixed(2));
        this.order.totalAmount = parseFloat(this.cartModel.total.Value.toFixed(2));
        this.order.taxrate = this.merchantInfo.settings.taxrate;
      } catch (e) {
        console.log(e);
        this.processing.inprogress = false;
        return;
      }
      if (!this.order.deliveryInstructions) {
        this.order.deliveryInstructions = '';
      }

      if (this.cartModel.deliveryInstructions) {
        this.order.deliveryInstructions = this.cartModel.deliveryInstructions;
      }
      // save the main order but not the CC info

      let ipAddress = await this.commonService.getIPAddress();

      if (this.order.ordertype === OrderTypeEnum.Delivery) {

        // payment object:
        const payment = new Payment();

        payment.id = uuid();

        payment.name = this.paymentMethod.name;

        customerName = this.paymentMethod.name.split(' ');

        switch (customerName.length) {
          case 2:
            payment.firstname = this.paymentMethod.name.split(' ')[0];
            payment.lastname = this.paymentMethod.name.split(' ')[1];
            break;
          case 3:
            payment.firstname = this.paymentMethod.name.split(' ')[0];
            payment.lastname = this.paymentMethod.name.split(' ')[2];
            break;
        }

        if (this.orderConfig.payment_api.hasOwnProperty('byPassBatchCheck')) {
          payment.byPassBatchCheck = this.orderConfig.payment_api.byPassBatchCheck;
        } else {
          payment.byPassBatchCheck = false;
        }

        payment.industryType = this.merchantInfo.industryType;
        payment.cardnumber = this.paymentMethod.cardnumber;
        payment.cc_exp_month = this.paymentMethod.paymethod.expm;
        payment.cc_exp_year = this.paymentMethod.paymethod.expy;
        payment.cvv = `${this.paymentMethod.cvv}`;
        payment.cc_type = this.paymentMethod.cardbrand;
        payment.amount = parseFloat(this.cartModel.total.Value.toFixed(2));
        payment.cc_last_four = this.paymentMethod.paymethod.last4;
        payment.address1 = this.paymentMethod.street1;
        payment.city = this.paymentMethod.city;
        payment.state = this.paymentMethod.state;
        payment.postalcode = this.paymentMethod.zip;
        payment.country = this.deliveryInfo.address.country;
        /*
         * payment.firstname, payment.lastname, payment.address1, payment.address2, payment.city, payment.state, payment.country
         */

        this.order.paymethods = [];
        this.order.paymethods.push(payment);
      }

      if (this.order.ordertype === OrderTypeEnum.PickUp) {
        // payment object:
        const payment = new Payment();
        payment.transactionCode = "4";
        payment.industryType = this.merchantInfo.industryType;
        payment.name = this.paymentMethod.name;

        customerName = this.paymentMethod.name.split(' ');

        switch (customerName.length) {
          case 2:
            payment.firstname = this.paymentMethod.name.split(' ')[0];
            payment.lastname = this.paymentMethod.name.split(' ')[1];
            break;
          case 3:
            payment.firstname = this.paymentMethod.name.split(' ')[0];
            payment.lastname = this.paymentMethod.name.split(' ')[2];
            break;
        }

        payment.cardnumber = this.paymentMethod.cardnumber;
        payment.cc_exp_month = this.paymentMethod.paymethod.expm;
        payment.cc_exp_year = this.paymentMethod.paymethod.expy;
        payment.cvv = `${this.paymentMethod.cvv}`;
        payment.cc_type = this.paymentMethod.cardbrand;
        payment.amount = parseFloat(this.cartModel.total.Value.toFixed(2));
        payment.cc_last_four = this.paymentMethod.paymethod.last4;
        payment.address1 = this.paymentMethod.street1;
        payment.city = this.paymentMethod.city;
        payment.state = this.paymentMethod.state;
        payment.postalcode = this.paymentMethod.zip;
        payment.country = this.deliveryInfo.address.country;

        /*
         * payment.firstname, payment.lastname, payment.address1, payment.address2, payment.city, payment.state, payment.country
         */

        this.order.paymethods = [];
        this.order.paymethods.push(payment);
      }

      this.localStorage.set('order', this.order).subscribe(() => { });

      if (this.isValidOrder()) {
        this.processing.inprogress = true;
        this.spinner.show();

        if (this.user && (!this.user.access_token || this.user.isGuestLogin)) {
          const guestuser = await this.GuestLogin();
          this.user.access_token = guestuser.access_token;
          this.localStorage.set('user', guestuser);
          localStorage.setItem('token', guestuser.access_token); // this is not the same as 'this.localStorage'
          this.localStorage.set('token', guestuser.access_token).subscribe(() => { });
        }

        logEntry.entry.text = "submitting order to api";

        if (this.loggerSubscription) {
          this.loggerSubscription.unsubscribe();
        }

        this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

        }, (error: any) => {
          console.log('log service error: ' + error.message);
        });

        this.order.orderSource = 'DeliverMe';
        this.order.dateTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York';
        
        this.orderService.submitOrder(this.order).subscribe(async (confirm: OrderConfirmation) => {

          this.orderResponse = JSON.parse(JSON.stringify(confirm));

          logEntry.entry.text = 'order response: ' + JSON.stringify(confirm);

          if (this.loggerSubscription) {
            this.loggerSubscription.unsubscribe();
          }

          this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

          }, (error: any) => {
            console.log('log service error: ' + error.message);
          });

          if (confirm) {
            clearInterval(this.timer);
            let message = {};
            let m_message = {};

            if (!this.baseUrl) {
              this.baseUrl = 'https://www.deliverme.com';
            }

            // if ((confirm.confirmation && confirm.confirmation > 0) && (confirm.status && confirm.status === true)) {
            if (confirm.paymentResponse && confirm.paymentResponse.ResponseCode === 0) {

              if (this.user.isAutoNotification) {
                // console.log(confirm.order);
                if (confirm.order && confirm.status === true && (confirm.order.orderstatus === OrderStatus.Pending || confirm.order.orderstatus === OrderStatus.Open || confirm.order.orderstatus === OrderStatus.Completed)) {
                  message = {
                    userid: this.user._id,
                    merchantid: this.merchantInfo._id,
                    to_numbers: [this.order.customerInformation.phone],
                    storename: this.merchantInfo.businessName,
                    type: 0,
                    message: this.merchantInfo.businessName + ' - Your order for $' + this.order.totalAmount.toFixed(2) +
                      ' has been submitted.' + '\n' +
                      'Authorization Number is ' + confirm.paymentResponse.AuthorizationNumber + '\n' +
                      'Please call ' + this.merchantInfo.phone + ' for any questions.' + '\n' +
                      'Click here to view your receipt: ' + this.baseUrl + '/receipt/' + confirm.order.receiptNumber
                  };

                  // console.log(OrderTypeEnum[confirm.order.ordertype]);
                  m_message = {
                    userid: this.user._id,
                    merchantid: this.merchantInfo._id,
                    to_numbers: [(this.merchantInfo.contactInfo && this.merchantInfo.contactInfo.phone) ? this.merchantInfo.contactInfo.phone : this.merchantInfo.phone],
                    storename: this.merchantInfo.businessName,
                    type: 0,
                    message: 'Order has been submitted for ' + OrderTypeEnum[confirm.order.ordertype] + '\n' +
                      'Authorization Number is ' + confirm.paymentResponse.AuthorizationNumber + '\n' +
                      'Click here to view order receipt: ' + this.baseUrl + '/merchant-receipt/' + confirm.order.receiptNumber
                  };

                  if (this.smsSubscription) {
                    this.smsSubscription.unsubscribe();
                  }

                  if (this.merchantSmsSubscription) {
                    this.merchantSmsSubscription.unsubscribe();
                  }

                  this.smsSubscription = this._smsSvc.sendMessage(message).subscribe(_ => {
                    if (this.merchantInfo.contactInfo &&
                      this.merchantInfo.contactInfo.contactMethod !== null &&
                      this.merchantInfo.contactInfo.contactMethod !== undefined &&
                      (this.merchantInfo.contactInfo.contactMethod === 1 ||
                        this.merchantInfo.contactInfo.contactMethod === 3)) {
                      this.merchantSmsSubscription = this._smsSvc.sendMessage(m_message).subscribe();
                    }
                  }, (error: any) => {
                    console.log("sms error: " + error.message);
                  });

                }
              }

              // small delay to gice backend pos time to generate an order number else display th einvoice in the email
              setTimeout(async () => {
                this.orderSubscription = this.orderService.getReceipt(confirm.order.receiptNumber).subscribe(async (orders: any) => {
                  if (orders.length > 0) {
                    if (orders[0].orderstatus === OrderStatus.Pending || orders[0].orderstatus === OrderStatus.Open || orders[0].orderstatus === OrderStatus.Completed) {
                      await this.sendEmailConfirmation(orders[0]).catch((error: any) => {});
                    }
                  }

                  this.processing.inprogress = false;
                  this.spinner.hide();

                  if (orders[0].orderstatus === OrderStatus.Pending || orders[0].orderstatus === OrderStatus.Open || orders[0].orderstatus === OrderStatus.Completed) {
                    this.processing.done = false;
                    this.processing.error = false;
                    this.clearCache();
                    this._router.navigate(['/receipt/' + confirm.order.receiptNumber]);
                  } else {
                    stepper.next();
                  }

                }, (error) => {
                  console.log(error);

                  this.processing.inprogress = false;
                  this.spinner.hide();

                  if (confirm.order.orderstatus === OrderStatus.Pending || confirm.order.orderstatus === OrderStatus.Open || confirm.order.orderstatus === OrderStatus.Completed) {
                    this.processing.done = false;
                    this.processing.error = false;
                    this.clearCache();
                    this._router.navigate(['/receipt/' + confirm.order.receiptNumber]);
                  } else {
                    let msg = confirm.message;
                    let details: string;

                    msg = 'There was an error processing your order. Please call ' + this.merchantInfo.phone + ' for further assistance.';
                    details = confirm.paymentResponse && confirm.paymentResponse.ResponseText ? confirm.paymentResponse.ResponseText : "";
                    stepper.next();
                  }
                });
              }, 3000);

            } else {

              if (confirm.message) {

                this.processing.done = false;
                this.processing.error = false;
                this.processing.inprogress = false;
                this.spinner.hide();

                let msg = confirm.message;
                let details: string;

                if (!confirm.status) {
                  msg = 'There was an error processing your order. Please call ' + this.merchantInfo.phone + ' for further assistance.';
                  details = confirm.paymentResponse && confirm.paymentResponse.ResponseText ? confirm.paymentResponse.ResponseText : "";
                }

                stepper.next();

              } else {
                this.processing.done = true;
                this.processing.error = true;
                this.processing.inprogress = false;
                this.spinner.hide();
                stepper.next();
              }
            }

          } else {
            // no order confirmation, try again?
            const msg = 'An error has occurred, please try again or call ' + this.merchantInfo.phone + ' for further assistance';
            const dref = this.confirmDialog.open(ConfirmDialogComponent, { data: { message: msg, text: '' } });

            dref.afterClosed().pipe(first()).subscribe((response: boolean) => {
              if (response === false) {
                this.clearCache();
                this.user = new User();
                this.order = new Order();
                this.cartModel = new StoreCartModel();
                this._router.navigate([this.merchantInfo.routeName]);
              } else {
                this.processing.done = false;
                this.processing.error = false;
                this.processing.inprogress = false;
                this.spinner.hide();
              }
              // this.location.back(); // <-- this takes me out of DM if I had opened a previous website like yahoo prior to opening DM
            });
          }
        }, ((error: any) => {

          logEntry.entryType = 'Error';
          logEntry.entry.error = error.message;
          logEntry.entry.text = 'Error occurred submitting order';

          if (this.loggerSubscription) {
            this.loggerSubscription.unsubscribe();
          }

          this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

          }, (error: any) => {
            console.log('log service error: ' + error.message);
          });

          this.processing.done = true;
          this.processing.error = true;
          this.processing.inprogress = false;
          this.spinner.hide();
          // this._OrderProcessed();

          let dref: any;

          /*           if (error.error) {
                      dref = this.confirmDialog.open(ConfirmDialogComponent, { data: { message: (error.error.message) ? error.error.message : 'Error occurred processing your order', text: '' } });
                    } else {
                      dref = this.confirmDialog.open(ConfirmDialogComponent, { data: { message: (error.message) ? error.message : 'Error occurred processing your order', text: '' } });
                    } */

          stepper.next();

          /*           dref.afterClosed().pipe(first()).subscribe((response: boolean) => {
                     if (response === false) {
                       this.clearCache();
                       this.user = new User();
                       this.order = new Order();
                       this.cartModel = new StoreCartModel();
                       this._router.navigate([this.merchantInfo.routeName]);
                     } else {
                       this.processing.done = false;
                       this.processing.error = false;
                       this.processing.inprogress = false;
                       this.spinner.hide();
                     }
                     // this.location.back(); // <-- this takes me out of DM if I had opened a previous website like yahoo prior to opening DM
                   }); */

        }));
      } else {
        this.processing.done = true;
        this.processing.error = true;
        this.processing.inprogress = false;
        this.spinner.hide();
      }

    } catch (error) {
      console.log(error.message);

      logEntry.entryType = 'Error';
      logEntry.entry.error = error.message;
      logEntry.entry.text = 'Error occurred submitting order';

      if (this.loggerSubscription) {
        this.loggerSubscription.unsubscribe();
      }

      this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {

      }, (error: any) => {
        console.log('log service error: ' + error.message);
      });

      this.processing.done = false;
      this.processing.error = false;
      this.processing.inprogress = false;
      this.spinner.hide();
      this._OrderProcessed();
    }
  }

  public correctOrder(stepper: MatStepper): void {
    this.processing.done = false;
    this.processing.error = false;
    this.processing.inprogress = false;
    stepper.previous();
  }

  public newOrder(stepper: MatStepper): void {
    this.clearCache();
    this.user = new User();
    this.order = new Order();
    this.cartModel = new StoreCartModel();
    this._router.navigate([this.merchantInfo.routeName]);
  }

  async sendEmailConfirmation(order: Order) {
    return new Promise(async (resolve, reject) => {
      try {
        const email: EmailOrderConfirmation = new EmailOrderConfirmation();
        const template: EmailOrderTemplate = new EmailOrderTemplate();

        template.viewonline = this.baseUrl + '/receipt/' + order.receiptNumber;
        template.merchantlogo = this.merchantInfo.logo;
        template.businessname = this.merchantInfo.businessName;
        template.merchantstreet = this.merchantInfo.address;
        template.merchantcity = this.merchantInfo.city;
        template.merchantstate = this.merchantInfo.state;
        template.merchantzipcode = this.merchantInfo.zip;
        template.merchantphone = this.merchantInfo.phone;
        template.orderno = order.orderxref;
        template.items = order.items;

        for (const item of template.items) {
          if (item.ratioGraphic && item.ratioGraphic.toLocaleLowerCase() !== 'none') {
            for (const side of item.sides) {
              side.price = side.price.toFixed(2);
            }

            for (const option of item.options) {
              option.price = option.price.toFixed(2);
            }

            item.wholeMods = [];
            item.wholeMods = item.wholeMods.concat(item.sides.filter(s => s.ratio === 0 || s.ratio === -99 || s.ratio === null));
            item.wholeMods = item.wholeMods.concat(item.options.filter(o => o.ratio === 0 || o.ratio === -99 || o.ratio === null));
            item.wholeMods = item.wholeMods.concat(item.modifiers.filter(m => m.ratio === 0 || m.ratio === -99 || m.ratio === null));
            item.wholeMods.forEach(m => {
              if (m.extra.length > 0 && m.extra[0].selected) {
                m.title = m.extra[0].title + ' ' + m.title;
              }
            });
            item.wholeModAvailable = item.wholeMods.length > 0 ? true : false;

            item.rightMods = [];
            item.rightMods = item.rightMods.concat(item.sides.filter(s => s.ratio === 1));
            item.rightMods = item.rightMods.concat(item.options.filter(o => o.ratio === 1));
            item.rightMods = item.rightMods.concat(item.modifiers.filter(m => m.ratio === 1));
            item.rightMods.forEach(m => {
              if (m.extra.length > 0 && m.extra[0].selected) {
                m.title = m.extra[0].title + ' ' + m.title;
              }
            });
            item.rightModAvailable = item.rightMods.length > 0 ? true : false;

            item.leftMods = [];
            item.leftMods = item.leftMods.concat(item.sides.filter(s => s.ratio === -1));
            item.leftMods = item.leftMods.concat(item.options.filter(o => o.ratio === -1));
            item.leftMods = item.leftMods.concat(item.modifiers.filter(m => m.ratio === -1));
            item.leftMods.forEach(m => {
              if (m.extra.length > 0 && m.extra[0].selected) {
                m.title = m.extra[0].title + ' ' + m.title;
              }
            });
            item.leftModAvailable = item.leftMods.length > 0 ? true : false;

            if (item.wholeMods.length > 0 || item.leftMods.length > 0 || item.rightMods.length > 0) {
              item.allowRatio = true;
            } else {
              item.allowRatio = false;
            }
          }
          // console.log(item);
        }

        template.subtotal = order.subtotal.toFixed(2);
        template.servicefee = (order.serviceFee > 0) ? order.serviceFee.toFixed(2) : '';
        template.conveniencefee = (order.convenienceFee > 0) ? order.convenienceFee.toFixed(2) : '';
        template.tax = order.tax.toFixed(2);
        template.gratuity = order.tip.toFixed(2);
        template.deliveryfee = (order.deliveryFee > 0) ? order.deliveryFee.toFixed(2) : '';
        template.totalamount = order.totalAmount.toFixed(2);
        template.ordertype = this.merchantInfo.orderOptions.find(t => t.value === order.ordertype).desc;
        template.customername = order.customerInformation.name;
        template.orderdatetime = order.datetime;

        switch (order.ordertype) {
          case OrderTypeEnum.Delivery:
            template.locationstreet = order.customerInformation.street1;
            template.locationcity = order.customerInformation.city;
            template.locationstate = order.customerInformation.state;
            template.locationzip = order.customerInformation.postalcode;
            template.locationphone = order.customerInformation.phone;
            template.locationemail = order.customerInformation.email;
            break;
          default:
            template.locationstreet = this.merchantInfo.address;
            template.locationcity = this.merchantInfo.city;
            template.locationstate = this.merchantInfo.state;
            template.locationzip = this.merchantInfo.zip;
            template.locationphone = this.merchantInfo.phone;
            template.locationemail = this.merchantInfo.email;
            break;
        }

        switch (order.paymethods[0].cc_type) {
          case 'visa':
            template.cardbrandlogo = 'v';
            break;
          case 'mastercard':
            template.cardbrandlogo = 'm';
            break;
          case 'american-express':
            template.cardbrandlogo = 'a';
            break;
          case 'diners-club':
            template.cardbrandlogo = 'dc';
            break;
          case 'discover':
            template.cardbrandlogo = 'd';
            break;
          case 'jcb':
            template.cardbrandlogo = 'jcb';
            break;
          case 'paypal':
            template.cardbrandlogo = 'p';
            break;
          case 'bitcoin':
            template.cardbrandlogo = 'bit';
            break;
          case 'maestro':
            template.cardbrandlogo = 'me';
            break;
          default:
            break;
        }

        template.cclastfour = order.paymethods[0].cc_last_four;
        template.cardholder = order.paymethods[0].name;
        template.paymentdatetime = moment.utc(order.paymethods[0].datetime).local().format('LLL');
        template.paymentid = (order.paymethods[0].response && order.paymethods[0].response[0].PaymentId) ? order.paymethods[0].response[0].PaymentId.toString() : '0';
        template.approvalcode = (order.paymethods[0].response && order.paymethods[0].response[0].AuthorizationNumber) ? order.paymethods[0].response[0].AuthorizationNumber : '';
        template.transactionidentifer = (order.paymethods[0].response && order.paymethods[0].response[0].TransactionIdentifier) ? order.paymethods[0].response[0].TransactionIdentifier : order.paymethods[0].response[0].TransactionIdentifer || '0';
        template.invoicenum = order.InvoiceNum;
        template.productlogo = 'https://epionlineorders.s3.us-east-2.amazonaws.com/assets/images/deliverme_logo.png';
        template.copyrightyear = new Date().getFullYear().toString();

        email.Source = 'noreply@deliverme.com';
        email.Template = 'epi_receipt';
        email.Destination.ToAddresses = [order.customerInformation.email];
        email.Destination.CcAddresses = [];
        email.TemplateData = JSON.stringify(template);

        if (this.emailSubscription) {
          this.emailSubscription.unsubscribe();
        }

        if (this.merchantEmailSubscription) {
          this.merchantEmailSubscription.unsubscribe();
        }

        // small delay to give the backend pos time to generate the order else display the invoice number instead.
        this.emailSubscription = this.emailService.sendEmailConfirmation(email).subscribe((result: any) => {
          // console.log(result);
          if (this.merchantInfo.contactInfo &&
            this.merchantInfo.contactInfo.contactMethod !== null &&
            this.merchantInfo.contactInfo.contactMethod !== undefined &&
            this.merchantInfo.contactInfo.contactMethod > 1) {
            template.viewonline = this.baseUrl + '/merchant-receipt/' + order.receiptNumber;
            email.Destination.ToAddresses = [(this.merchantInfo.contactInfo && this.merchantInfo.contactInfo.email) ? this.merchantInfo.contactInfo.email : this.merchantInfo.email];
            email.Template = 'epi_receipt_merchant';
            email.TemplateData = JSON.stringify(template);

            this.merchantEmailSubscription = this.emailService.sendEmailConfirmation(email).subscribe((res: any) => {
              resolve(res);
            }, (err: any) => {
              console.log(err);
              reject(err);
            });
          } else {
            resolve(result);
          }
        }, (error: any) => {
          console.log(error);
          reject(error);
        });

      } catch (error) {
        console.log(error.message);
        reject(error);
      }
    });
  }

  clearCache(): void {
    try {
      this.localStorage.delete(this.merchantInfo._id).subscribe();
      /*       this.localStorage.delete('token'n) */
      this.localStorage.delete('order').subscribe(() => { });
      this.localStorage.delete('schedule').subscribe(() => { });
      this.localStorage.delete('deliveryinfo').subscribe(() => { });
      this.localStorage.delete('paymethod').subscribe(() => { });
      this.localStorage.delete('tip').subscribe(() => { });
      this.localStorage.delete('cart').subscribe(() => { });
    } catch (error) {
      console.log(error);
    }
  }
  // post processing?
  private _OrderProcessed() {
    if (this.processing.error) {
      console.log('an error occurred');
    } else {
      console.log('processed successfully');

      /*       setTimeout(() => {
              this._router.navigate([this.activeRoute.snapshot.params['storename']]);
            }, 3000); */
    }
  }

  public async submitPayment(mode: string, stepper: MatStepper): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {

        this.disableApplePay = true;
        this.processing.inprogress = true;
        this.spinner.show();

        this.order.applicationKey = this.merchantInfo.applicationKey;
        this.order.merchantNumber = this.merchantInfo.merchantNumber1;
        this.order.businessName = this.merchantInfo.businessName;
        this.order.paymentType = 'ap';
        this.order.requestID = uuid();
        this.order.orderSource = 'DeliverMe';

        switch (this.cartModel.deliverytype) {
          case OrderTypeEnum.Delivery:
          case OrderTypeEnum.PickUp:

            if (this.deliveryInfo) {
              this.order.customerInformation.name = this.deliveryInfo.name;
              this.order.customerInformation.firstName = this.deliveryInfo.firstname;
              this.order.customerInformation.lastName = this.deliveryInfo.lastname;
              this.order.customerInformation.email = this.deliveryInfo.email;
              this.order.customerInformation.phone = this.deliveryInfo.phone;
              this.order.customerInformation.street1 = this.deliveryInfo.address.street1;
              this.order.customerInformation.street2 = this.deliveryInfo.address.street2 || '';
              this.order.customerInformation.city = this.deliveryInfo.address.city;
              this.order.customerInformation.state = this.deliveryInfo.address.state;
              this.order.customerInformation.postalcode = this.deliveryInfo.address.postalCode;
              this.order.customerInformation.country = this.deliveryInfo.address.country || 'USA';
            }
            break;
          default:
            if (this.paymentMethod) {
              this.order.customerInformation.firstName = this.paymentMethod.name.split(' ')[0];
              this.order.customerInformation.lastName = this.paymentMethod.name.split(' ')[1];
              this.order.customerInformation.street1 = this.paymentMethod.street1;
              this.order.customerInformation.street2 = '';
              this.order.customerInformation.city = this.paymentMethod.city;
              this.order.customerInformation.state = this.paymentMethod.state;
              this.order.customerInformation.postalcode = this.paymentMethod.zip;
            }
            break;
        }

        // user should either be guest or their account:
        this.order.userid = this.user._id;
        this.order.datetime = moment(this.cartModel.schedule.value).format('LLL');
        this.order.ordertype = this.cartModel.deliverytype;
        this.order.asap = this.cartModel.schedule.asap;

        // console.log(this.cartModel.schedule.value.toString());
        // make a new copy of the itemList:
        this.order.items = JSON.parse(JSON.stringify(this.cartModel.itemList));

        try {
          this.order.tip = parseFloat(this.cartModel.tip.Value.toFixed(2)); // Tri - Replacing Tip Percent with Actual Tip Amount
          this.order.serviceFee = parseFloat(this.cartModel.servicefee.Value.toFixed(2));
          this.order.convenienceFee = parseFloat(this.cartModel.conveniencefee.Value.toFixed(2));
          this.order.tax = parseFloat(this.cartModel.tax.Value.toFixed(2));
          this.order.subtotal = parseFloat(this.cartModel.subtotal.Value.toFixed(2));
          this.order.totalAmount = parseFloat(this.cartModel.total.Value.toFixed(2));
          this.order.taxrate = this.merchantInfo.settings.taxrate;
        } catch (e) {
          console.log(e);
          this.disableApplePay = false;
          this.processing.inprogress = false;
          this.spinner.hide();
          return;
        }

        switch (mode) {
          case 'a':
            this.applePay = new ApplePay(this.order, this.http, this.orderService, this.applePayConfig);
            const result = await this.applePay.displayPaymentSheet().catch((err: any) => {

              let error: any;

              if (err.error) {
                error = err.error;
              } else {
                error = err;
              }

              if (error) {
                this.processing.done = false;
                this.processing.error = false;
                this.processing.inprogress = false;
                this.spinner.hide();

                let msg = error.message;
                let details: string;

                if (error.paymentResponse && error.paymentResponse.ResponseCode === 1) {
                  msg = 'There was an error processing your payment';
                  details = error.paymentResponse.ResponseText + '<br>' + error.paymentResponse.AVSResponseText + '<br>' + error.paymentResponse.CVVResponseText;
                } else if (error.paymentResponse && error.paymentResponse.ResponseCode === 0) {
                  msg = 'There was an error processing your order. Please call ' + this.merchantInfo.phone + " to confirm order.";
                  details = error.paymentResponse.ResponseText + '<br>' + error.paymentResponse.AVSResponseText + '<br>' + error.paymentResponse.CVVResponseText;
                }

                const dref = this.confirmDialog.open(ConfirmDialogComponent, { data: { message: msg, text: details } });

                dref.afterClosed().pipe(first()).subscribe((response: boolean) => {
                  if (response === false) {
                    this.clearCache();
                    this.user = new User();
                    this.order = new Order();
                    this.cartModel = new StoreCartModel();
                    this._router.navigate([this.merchantInfo.routeName]);
                  } else {
                    this.processing.done = false;
                    this.processing.error = false;
                    this.processing.inprogress = false;
                    this.spinner.hide();
                  }
                });
              }
            }).then(async (result) => {
              this.disableApplePay = false;

              if (result) {

                if (this.timer) {
                  clearInterval(this.timer);
                }

                let message = {};
                let mMessage = {};

                if (!this.baseUrl) {
                  this.baseUrl = 'https://www.deliverme.com';
                }

                if (result.paymentResponse && result.paymentResponse.ResponseCode === 0) {
                  if (this.user.isAutoNotification) {
                    // if (result.order && result.order.receiptNumber) {
                    if (result.order && result.status === true && (result.order.orderstatus === OrderStatus.Pending || result.order.orderstatus === OrderStatus.Open || result.order.orderstatus === OrderStatus.Completed)) {
                      message = {
                        userid: this.user._id,
                        merchantid: this.merchantInfo._id,
                        to_numbers: [this.order.customerInformation.phone],
                        storename: this.merchantInfo.businessName,
                        type: 0,
                        message: this.merchantInfo.businessName + ' - Your order for $' + this.order.totalAmount.toFixed(2) +
                          ' has been submitted.' + '\n' +
                          'Authorization Number is ' + result.paymentResponse.AuthorizationNumber + '\n' +
                          'Please call ' + this.merchantInfo.phone + ' for any questions.' + '\n' +
                          'Click here to view your receipt: ' + this.baseUrl + '/receipt/' + result.order.receiptNumber
                      };

                      // console.log(OrderTypeEnum[confirm.order.ordertype]);
                      mMessage = {
                        userid: this.user._id,
                        merchantid: this.merchantInfo._id,
                        to_numbers: [(this.merchantInfo.contactInfo && this.merchantInfo.contactInfo.phone) ? this.merchantInfo.contactInfo.phone : this.merchantInfo.phone],
                        storename: this.merchantInfo.businessName,
                        type: 0,
                        message: 'Order has been submitted for ' + OrderTypeEnum[result.order.ordertype] + '\n' +
                          'Authorization Number is ' + result.paymentResponse.AuthorizationNumber + '\n' +
                          'Click here to view order receipt: ' + this.baseUrl + '/merchant-receipt/' + result.order.receiptNumber
                      };
                    } else {
                      message = {
                        userid: this.user._id,
                        merchantid: this.merchantInfo._id,
                        to_numbers: [this.order.customerInformation.phone.replace(/[^0-9]/g, '')],
                        storename: this.merchantInfo.businessName,
                        type: 0,
                        message: this.merchantInfo.businessName + ' - Your order for $' + this.order.totalAmount.toFixed(2) +
                          ' has been submitted.' + '\n' +
                          'Authorization Number is ' + result.paymentResponse.AuthorizationNumber + '\n' +
                          'Please call ' + this.merchantInfo.phone + ' for any questions.' +
                          result.message.replace('<br>', '\n')
                      };

                      mMessage = {
                        userid: this.user._id,
                        merchantid: this.merchantInfo._id,
                        to_numbers: [(this.merchantInfo.contactInfo && this.merchantInfo.contactInfo.phone) ? this.merchantInfo.contactInfo.phone : this.merchantInfo.phone],
                        storename: this.merchantInfo.businessName,
                        type: 0,
                        message: 'Order has been submitted for ' + OrderTypeEnum[result.order.ordertype] + '\n' +
                          'Authorization Number is ' + result.paymentResponse.AuthorizationNumber + '\n' +
                          result.message.replace('<br>', '\n')
                      };
                    }

                    if (this.smsSubscription) {
                      this.smsSubscription.unsubscribe();
                    }

                    if (this.merchantSmsSubscription) {
                      this.merchantSmsSubscription.unsubscribe();
                    }

                    this.smsSubscription = this._smsSvc.sendMessage(message).subscribe(_ => {
                      if (!this.merchantInfo.contactInfo ||
                        this.merchantInfo.contactInfo.contactMethod === null ||
                        this.merchantInfo.contactInfo.contactMethod === undefined ||
                        this.merchantInfo.contactInfo.contactMethod === 1 ||
                        this.merchantInfo.contactInfo.contactMethod === 3) {
                        this.merchantSmsSubscription = this._smsSvc.sendMessage(mMessage).subscribe();
                      }
                    });
                  }

                  /*                  await this.sendEmailConfirmation(result.order);

                                   this.processing.done = true;
                                   this.processing.error = false;
                                   this.processing.inprogress = false;
                                   this.spinner.hide();

                                   this.clearCache();
                                   if (result.order.receiptNumber) {
                                     this._router.navigate(['/receipt/' + result.order.receiptNumber]);
                                   } */

                  setTimeout(async () => {

                    this.orderSubscription = this.orderService.getReceipt(result.order.receiptNumber).subscribe(async (orders: any) => {

                      if (orders.length > 0) {
                        if (orders[0] && result.status === true && (orders[0].orderstatus === OrderStatus.Pending || orders[0].orderstatus === OrderStatus.Open || orders[0].orderstatus === OrderStatus.Completed)) {
                          await this.sendEmailConfirmation(orders[0]);
                        }
                      }

                      this.processing.done = false;
                      this.processing.error = false;
                      this.processing.inprogress = false;
                      this.spinner.hide();

                      this.clearCache();
                      if (result.order.receiptNumber) {
                        this._router.navigate(['/receipt/' + result.order.receiptNumber]);
                      }
                    }, (error) => {
                      console.log(error);
                      this.processing.done = false;
                      this.processing.error = false;
                      this.processing.inprogress = false;
                      this.spinner.hide();

                      this.clearCache();
                      if (result.order.receiptNumber) {
                        this._router.navigate(['/receipt/' + result.order.receiptNumber]);
                      }
                    });
                  }, 2000);

                } else {

                  if (result.message) {

                    this.processing.done = false;
                    this.processing.error = false;
                    this.processing.inprogress = false;
                    this.spinner.hide();

                    let msg = result.message;
                    let details: string;

                    if (result.paymentResponse && result.paymentResponse.ResponseCode === 1) {
                      msg = 'There was an error processing your order. Please call ' + this.merchantInfo.phone + ' for further assistance.';
                      details = result.paymentResponse.ResponseText;
                    }

                    stepper.next();

                  } else {
                    this.processing.done = true;
                    this.processing.error = true;
                    this.processing.inprogress = false;
                    this.spinner.hide();
                    stepper.next();
                  }
                }
              } else {
                const msg = 'An error has occurred, please try again or call ' + this.merchantInfo.phone + ' for further assistance';
                const dref = this.confirmDialog.open(ConfirmDialogComponent, { data: { message: msg, text: '' } });

                dref.afterClosed().pipe(first()).subscribe((response: boolean) => {
                  if (response === false) {
                    this.clearCache();
                    this.user = new User();
                    this.order = new Order();
                    this.cartModel = new StoreCartModel();
                    this._router.navigate([this.merchantInfo.routeName]);
                  } else {
                    this.processing.done = false;
                    this.processing.error = false;
                    this.processing.inprogress = false;
                    this.spinner.hide();
                  }
                });
              }
            });
            break;
          case 'g':
            break;
          case 's':
            // this.samsungPay.onButtonClick();
            break;
        }
      } catch (error) {
        console.log(error);
        this.disableApplePay = false;
        reject(error);
      }
    });
  }
}
